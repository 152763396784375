export default class InfiniteItems {
  constructor(Resource, config) {
    this.page = config.page;
    this.perPage = config.per_page;
    this.listSize = config.list_size;
    this.filter = config.filter;
    console.log("this.filter", this.filter);
    this.locale_country = config.locale_country;

    this.fetchPromise = Promise;
    this.Resource = Resource;
    this.reset();
  }

  reset() {
    this.totalCount = -1;
    this.currentPage = 0;
    this.page = 1;
    this.pages = -1;
    this.items = [];
    this.fetchPending = false;
    this.fetchingPage = -1;
  }

  getFilter() {
    return this.filter;
  }

  mergeFilter = (filter) => {
    if (filter == null) {
      filter = {};
    }
    for (let key in filter) {
      this.filter[key] = filter[key];
    }
    return this.filter;
  };

  getItemAtIndex(index) {
    if (index <= this.items.length) {
      return this.items[index];
    } else {
      return this.fetchItems(
        parseInt((index * this.listSize) / this.perPage) + 1,
        filter
      );
    }
  }

  addItems(items, metadata) {
    let index = 0;
    let itemList = [];
    const that = this;
    items.forEach(function(item) {
      if (index > that.listSize - 1) {
        // ^^ don't know this scenario.
        that.items.push(itemList);
        itemList = [];
        index = 0;
      }

      itemList.push(item);
      index += 1;
    });

    if (itemList.length > 0) {
      this.items.push(itemList);
    }

    if (!!metadata) {
      // server returns no metadata.
      this.totalCount = parseInt(metadata.total_count / this.listSize);
      this.currentPage = metadata.current_page;
      this.perPage = parseInt(metadata.per_page / this.listSize);
      this.pages = metadata.pages;
    } else {
      this.currentPage += 1;
      if (this.items.length === this.perPage) {
        this.totalCount = Infinity;
      } else {
        this.totalCount = this.items.length;
      }
    }
  }

  fetchInitialItems(filter, latLng) {
    if (filter == null) {
      ({ filter } = this);
    }
    
    this.reset();
    return this.fetchItems(this.page, filter, latLng);
  }

  fetchMoreItems(currentPage, filter, latLng) {
    if (currentPage == null) {
      ({ currentPage } = this);
    }
    if (filter == null) {
      ({ filter } = this);
    }
    return this.fetchItems(currentPage + 1, filter, latLng);
  }

  fetchItems(page, filter, latLng) {
    if (!!this.fetchPending) {
      return this.fetchPromise;
    }

    console.log(
      "page: " + page + ", with filter: " + filter + " -> fetchItems invoked!"
    );

    this.fetchPending = true;

    const params = {
      page,
      per_page: this.perPage,
      country_code: this.locale_country,
    };

    for (let key in filter) {
      const value = filter[key];
      if (!!value) {
        params[key] = value;
      }
    }

    if (!!latLng) {
      params.lat = latLng.lat;
      params.lng = latLng.lng;
    }

    if (!!this.Resource.search) {
      this.fetchPromise = this.search(params);
    } else {
      this.fetchPromise = this.query(params);
    }

    this.fetchPromise.then(
      (records) => {
        if (records == null) {
          records = [];
        }
        this.fetchPending = false;
        this.page = this.perPage > records.length ? -1 : page;
        this.addItems(records);
        return this.items;
      },
      (error) => {
        this.fetchPending = false;
        return error;
      }
    );

    return this.fetchPromise;
  }

  search(params) {
    return new Promise((resolve, reject) => {
      this.Resource.search(
        {},
        params,
        (records) => resolve(records),
        (error) => reject(error)
      );
    });
  }

  query(params) {
    return new Promise((resolve, reject) => {
      this.Resource.query(
        {},
        params,
        (records) => resolve(records),
        (error) => reject(error)
      );
    });
  }
}
