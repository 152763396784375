
export const buildOrderItemIngredientFromMenuItemIngredient = (menuItemIngredient) => ({
  menu_item_ingredient_id: menuItemIngredient.id,
  menu_item_ingredient: menuItemIngredient,
  number: 1
})

export const buildOrderItemIngredientsFromMenuItemIngredients = (menuItemIngredients) => {
  return menuItemIngredients.map(buildOrderItemIngredientFromMenuItemIngredient)
}
