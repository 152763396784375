import FoodnerdResource from './FoodnerdResource';

export default class AddressResource {
  static get(Rails) {
    const Address = new FoodnerdResource(Rails.access_token, `${ Rails.host }/api/addresses/{:id}.json`, { id: ':id' }, {
      default_address: {
        method: 'PUT',
        url: "/api/addresses/default_address.json",
        params: { user_id: ":user_id" }
      }
    });


    return Address;
  }
}

