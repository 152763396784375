import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { I18nRails } from "../../../shared/rails-i18n-js";
import {date as dateFilter} from "../../filters";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Zoom from "react-medium-image-zoom";
import LocationMenuItem from "./MenuItem";
import * as rh from "../helpers/routes";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import LinkTab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field, ErrorMessage } from "formik";

import UserResource from "../../resources/user";
import { getMenuOffer } from "../../models/menu";
import { isLoggedIn, loadImage } from "../helpers/utils";
import { isUserLiked } from "../../models/user";

export default function LocationMenu({
  Rails,
  onSubmitReview,
  review_title,
  user,
  fndLocation,
  openReviewModal,
  showReviewDialog,
  setShowReviewDialog,
  closeReviewDialog,
  toggleLike,
  reviews,
  class_for_option_value,
  addToBasketDirect,
  openMenuItemModal
}) {
  const [tabValue, setTabValue] = useState(0);

  const children = ({
    values,
    errors,
    handleChange,
    touched,
    isSubmitting,
    isValidating,
  }) => {
    return (
      <Form method='post' className='login-form'>
        <Grid container>
          <Grid xs={9} item>
            {/* <Field browser-default(label="Your feedback", style={{width:'100%'}}, maxLength={50}, placeholder={review_title}, onChange={(e) => props.component.setState({reviewContent: e.target.value})}, value={reviewContent}, required, type='text' /> */}
            <Field browser-default name='reviewContent' onChange={handleChange} style={{width:'100%'}} placeholder={review_title} required type='text' />
            <ErrorMessage name='reviewContent' component='validation-message' />
          </Grid>
          <Grid xs={3} item style={{padding:'2%'}}>
            <Button 
              className='tick-black btn-dark' 
              disabled={Object.keys(errors).length || isSubmitting || isValidating} 
              type='submit'
            >
              Send
              <i className='fas fa-check' aria-hidden='true'/>
            </Button>
          </Grid>
        </Grid>
      </Form>
    );

    // return pug`
    //   Form(method='post').login-form
    //     Grid(container)
    //       Grid(xs=9 item)
    //         // Field.browser-default(label="Your feedback", style={width:'100%'} , maxLength=50, placeholder=review_title, onChange=(e) => props.component.setState({reviewContent: e.target.value}), value=reviewContent, required, type='text')
    //         Field.browser-default(name='reviewContent', onChange=handleChange,  style={width:'100%'} , placeholder=review_title, required, type='text')
    //         ErrorMessage(name='reviewContent', component='validation-message')
    //       Grid(xs=3 item style={padding:'2%'} )
    //         Button.tick-black.btn-dark(disabled=Object.keys(errors).length || isSubmitting || isValidating, type='submit' ) Send
    //           i.fas.fa-check(aria-hidden='true')
    // `;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {!fndLocation || !fndLocation.menus ? (
            <CircularProgress />
          ) : (
            <AppBar position="static" style={{ marginBottom: "3%" }}>
              <Tabs
                value={tabValue}
                onChange={(e, newValue) => setTabValue(newValue)}
                style={{ backgroundColor: "white", color: "black" }}
                variant="scrollable"
                scrollButtons="on"
              >
                {fndLocation.menus.map((menu, index) => (
                  <LinkTab
                    label={menu.title}
                    key={index + "ms-" + index}
                    href={"#menu-" + index + "-" + menu.id}
                    spy="true"
                    smooth={true}
                    duration={450}
                    offset={-150}
                  />
                ))}
              </Tabs>
            </AppBar>
          )}
        </Grid>
        <Grid item xs={12}>
          <Card elevation={5}>
            {fndLocation.menus.map((menu, index_1) => {
              if (menu.menu_items.length > 0) {
                let menuOffer = getMenuOffer(menu);
                return (
                  <div
                    style={{ textAlign: "center" }}
                    id={"menu-" + index_1 + "-" + menu.id}
                    key={index_1 + "m-" + index_1}
                  >
                    <img
                      className="dots responsive-img"
                      alt=""
                      src={loadImage("dots.png")}
                    />
                    <span />
                    <p
                      className="menu-heading dots"
                      id={"menu-" + index_1 + "-" + menu.id}
                      key={index_1 + "m-" + index_1}
                    >
                      {menu.title}
                      {menuOffer && (
                        <span className="offer-color">{menuOffer}</span>
                      )}
                      <a
                        className="btn-reviews-rest"
                        onClick={(e) =>
                          openReviewModal("Menu", menu, e)
                        }
                      >
                        <i className="fas fa-comment" aria-hidden="true"></i>
                      </a>
                    </p>
                    <img
                      className="dots responsive-img"
                      alt=""
                      src={loadImage("dots.png")}
                    />
                    {menu.menu_items.map((menu_item, index_2) => (
                      <LocationMenuItem
                        Rails={Rails}
                        user={user}
                        fndLocation={fndLocation}
                        menu_item={menu_item}
                        menu={menu}
                        index_2={index_2}
                        cindex={menu_item.id}
                        openMenuItemModal={openMenuItemModal}
                        openReviewModal={openMenuItemModal}                    
                        toggleLike={toggleLike}
                        key={index_2 + menu_item.id}
                        class_for_option_value={class_for_option_value}
                        addToBasketDirect={addToBasketDirect}
                      />
                    ))}
                  </div>
                );
              }
            })}
          </Card>
        </Grid>
      </Grid>
      <Dialog 
        open={showReviewDialog} 
        onClose={closeReviewDialog} 
        fullWidth={true} 
        maxWidth='sm'
      >
        <DialogTitle style={{paddingBottom:'0px'}}>
          <Grid container>
            <Grid xs={11} item>
              <h5 style={{fontWeight:'600'}}>{review_title}</h5>
            </Grid>
            <Grid xs={1} item style={{textAlign:'right'}}>
              <IconButton aria-label="close" onClick={(e) => setShowReviewDialog(false)}>
                <CloseIcon style={{color:'#ef757f'}} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {isLoggedIn(Rails) ?
            <Formik personal-form onSubmit={onSubmitReview} initialValues={{reviewContent: ""}}>
              {children}
            </Formik>
            :
            <Grid container>
              <Grid xs={12} item>
                <Link to={rh.login()}>
                  <span className="inNav">{I18nRails.t("Web.login")}</span>
                </Link>
                to provide review for component menu
              </Grid>
            </Grid>
          }
          {reviews &&
            <Grid container>
              {reviews.map((review, index_7) =>
                <Grid item xs={12} key={index_7}>
                  <Card style={{marginTop: '3%'}} elevation={4}>
                    <Grid container>
                      <Grid xs={3} item style={{padding: '2%'}}>
                        <div className="avatar-img fndLocation-modal">
                          {!!review.user.user_image_url ?
                            <img src={review.user.user_image_url} onError={(e) => e.target.src=loadImage('profile_placeholder_large.png')} className="responsive-img logo-container" />
                            : null
                          }
                        </div>
                      </Grid>
                      <Grid xs={8} item>
                        <h4 style={{marginBottom:0, marginTop:2}}>{review.user.first_name} {review.user.last_name}</h4>
                        <h6 style={{marginBottom:0, marginTop:2, fontStyle:'italic', fontWeight:'300'}}>(at {dateFilter(review.created_at)})</h6>
                        <p style={{fontSize:'14px'}}>"{review.content}"</p>
                      </Grid>
                      <Grid xs={1} item style={{paddingTop:'3%'}}>
                        {isLoggedIn(Rails) &&
                          <a className="right" onClick={(e) => toggleLike(review, "Review", e)}>
                            <i className={`fas fa-heart ${user && isUserLiked(user, review, "Review") ? 'red-text' : ''}`}></i>
                            {/* | {review.favorites_count} */}
                          </a>
                        }
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )}
            </Grid>
          }
        </DialogContent>
      </Dialog>
    </>
  );
  
  // return pug`
  //   Grid(container)
  //     Grid(item xs=12)
  //       if !fndLocation || !fndLocation.menus
  //         CircularProgress
  //       else
  //         AppBar(position="static" style={marginBottom:'3%'})
  //           Tabs(value=tabValue, onChange=(e, newValue) => setTabValue(newValue), style={ backgroundColor:'white', color:'black'}, variant="scrollable", scrollButtons="on")
  //             each menu, index in fndLocation.menus
  //               LinkTab(label=menu.title, key=index+'ms-'+index, href='#menu-' + index + '-' + menu.id, spy="true", smooth=true, duration=450, offset=-150)
  //         Grid(item xs=12)
  //           Card(elevation=5)
  //             each menu, index_1 in fndLocation.menus
  //               if menu.menu_items.length > 0
  //                 div(style={textAlign:'center'} id='menu-' + index_1 + '-' + menu.id, key=index_1+'m-'+index_1)
  //                   img.dots.responsive-img(alt='', src=loadImage("dots.png"))
  //                   span
  //                   p.menu-heading.dots(id='menu-' + index_1 + '-' + menu.id, key=index_1+'m-'+index_1) ${ menu.title
  //   }
  //                   - let menuOffer = props.component.getMenuOffer(menu)
  //                   if menuOffer
  //                     span.offer-color #{menuOffer}
  //                   a.btn-reviews-rest(onClick=(e) => props.component.openReviewModal('Menu', menu, e))
  //                     i.fas.fa-comment(aria-hidden='true')
  //                     // |  ${ menu.reviews_count }
  //                   img.dots.responsive-img(alt='', src=loadImage("dots.png"))
  //                 each menu_item, index_2 in menu.menu_items
  //                   MenuItem(...props, key=index_2+menu_item.id, menu_item=menu_item, menu=menu, index_2=index_2)
  //   Dialog(open=props.component.state.showReviewDialog, onClose=props.component.closeReviewDialog, fullWidth='true', maxWidth='sm')
  //     DialogTitle(style={paddingBottom:'0px'}) 
  //       Grid(container)
  //         Grid(xs=11 item)
  //           h5(style={fontWeight:'600'}) ${ review_title }
  //         Grid(xs=1 item style={textAlign:'right'})
  //           IconButton(aria-label="close" onClick=(e)=>props.component.setState({showReviewDialog: false}))
  //             CloseIcon(style={color:'#ef757f'})
  //     DialogContent
  //       if props.component.isLoggedIn()
  //         Formik.personal-form(onSubmit=onSubmitReview, initialValues={ reviewContent: ""})
  //           = children.bind(props.component)
  //       else
  //         Grid(container)
  //           Grid(xs=12 item)
  //             Link(to=rh.login())
  //               span.inNav ${ I18nRails.t("Web.login") }
  //             |  to provide review for component menu
  //       if props.component.state.reviews
  //         Grid(container)
  //           each review, index_7 in props.component.state.reviews
  //             Grid(item xs=12 key=index_7)
  //               Card(style={marginTop:'3%'} elevation=4)
  //                 Grid(container)
  //                   Grid(xs=3 item style={padding:'2%'})
  //                     .avatar-img.fndLocation-modal
  //                       if !!review.user.user_image_url
  //                         img(src=review.user.user_image_url, onError=e => e.target.src=loadImage('profile_placeholder_large.png'), className:'responsive-img logo-container ')
  //                   Grid(xs=8 item)
  //                     h4(style={marginBottom:0, marginTop:2}) ${ review.user.first_name
  //   } ${ review.user.last_name } 
  //                     h6(style={marginBottom:0, marginTop:2, fontStyle:'italic', fontWeight:'300'}) (at ${ filters.date(
  //     review.created_at
  //   ) })
  //                     p(style={fontSize:'14px'}) "${ review.content }"
  //                   Grid(xs=1 item style={paddingTop:'3%'})
  //                     if props.component.isLoggedIn()
  //                       a.right(onClick=(e) => props.component.toggleLike(review, "Review", e))
  //                         i.fas.fa-heart(className=(user && isUserLiked(user, review, "Review")) ? 'red-text' : '')
  //                         // | ${ review.favorites_count }

  // `;
};

