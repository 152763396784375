import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { PayPalButton } from "react-paypal-button-v2";
import { spacing } from "@mui/system";
import { I18nRails } from "../../../shared/rails-i18n-js";
import ShowCart from "./ShowCart";

const template = ({
  basket,
  channel,
  setBasketField,
  verifyVoucherCode,
  changeQuantity,
  clearCart,
  proceedToCheckout,
  order,
  radioValue,
  orderProcessing,
  paypalProps,
  placeOrder,
}) => {
  return (
    <>
      <ShowCart
        hideCheckout={true}
        basket={basket}
        channel={channel}
        setBasketField={setBasketField}
        verifyVoucherCode={verifyVoucherCode}
        changeQuantity={changeQuantity}
        clearCart={clearCart}
        proceedToCheckout={proceedToCheckout}
        order={order}
      />
      <Grid container spacing={2}>
        <Grid item xs={5} md={10} />
        <Grid item xs={7} md={2}>
          {radioValue.admin_name === "paypal" &&
          basket.totals.numberOfCartItems > 0 ? (
            <PayPalButton disabled={orderProcessing} {...paypalProps} />
          ) : radioValue.admin_name !== "paypal" &&
            basket.totals.numberOfCartItems > 0 ? (
            <Button
              className="payment-button"
              type="submit"
              variant="contained"
              style={{
                width: "83%",
                marginTop: "3%",
                paddingTop: "4%",
                paddingBottom: "4%",
                marginLeft: "19%",
              }}
              color="primary"
              onClick={(e) => placeOrder()}
            >
              <i className="fas fa-file-invoice-dollar white-text"></i>
              <label className="white-text">
                {I18nRails.t("Web.confirm_order")}
              </label>
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </>
  );

  // return pug`
  //   ShowCart(hideCheckout=true, component=props.component)
  //   Grid(container, spacing=2)
  //     Grid(item xs=5 md=10)
  //     Grid(item xs=7 md=2)
  //       if props.component.state.radioValue.admin_name == 'paypal' && props.component.state.basket.totals.numberOfCartItems > 0
  //         PayPalButton(disabled=props.component.state.orderProcessing, ...props.component.paypalProps)
  //       else if props.component.state.radioValue.admin_name != 'paypal' && props.component.state.basket.totals.numberOfCartItems > 0
  //         Button.payment-button( type="submit", variant="contained", style={width:'83%', marginTop:'3%', paddingTop:'4%', paddingBottom:'4%', marginLeft: '19%' }, color="primary", onClick=(e)=> props.component.placeOrder())
  //           i.fas.fa-file-invoice-dollar.white-text()
  //           label.white-text ${ I18nRails.t('Web.confirm_order') }
  // `;
};

export default template;
