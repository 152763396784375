import React from "react";
import Zoom from "react-medium-image-zoom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { I18nRails } from "../../../shared/rails-i18n-js";

import "react-medium-image-zoom/dist/styles.css";
import LocationResource from "../../resources/location";
import { getLocationTime, locationTimeToday, location_address, location_header, location_logo_url, location_main_image_url } from "../../models/location";

const template = ({
  fndLocation,
  openHours
}) => {
  let fdLocation;
  if(fndLocation) {
    fdLocation = LocationResource.attachMethods(fndLocation);
  }
  const backgroundImg = location_main_image_url(fdLocation)
  return (
    <>
      {fndLocation && (
        <>
          <div
            className="half-bg-img"
            style={{
              marginTop: "5%",
              backgroundImage: 'linear-gradient(rgba(15, 22, 39, .7),rgba(15, 22, 39, .7)), url('+backgroundImg+')'
            }}
          >
            <Grid container style={{ marginTop: "4%" }}>
              <Grid xs={3} item style={{ paddingLeft: "3%" }}>
                <span id="heading_logo">
                  {fndLocation.logo_url && (
                    <img
                      alt={fndLocation.name}
                      src={location_logo_url(fndLocation)}
                      style={{ height: "160px", borderRadius: "9px" }}
                    />
                  )}
                </span>
              </Grid>
              <Grid xs={7} item>
                <div className="left-align">
                  <h3 className="white-text">{fndLocation.name}</h3>
                  {fndLocation.addresses && (
                    <p className="white-text">
                      {location_address(fndLocation).full_address}
                    </p>
                  )}
                  {locationTimeToday(fndLocation) && (
                    <p className="white-text">{getLocationTime(fndLocation)}</p>
                  )}
                  {fndLocation.min_delivery_price && (
                    <p className="white-text">
                      {I18nRails.t("Web.min_order")}: {fndLocation.min_delivery_amount}
                    </p>
                  )}
                  {location_header(fndLocation) && (
                    <p className="white-text">{"Note: " + location_header(fndLocation)}</p>
                  )}
                  <br />
                </div>
              </Grid>
              <Grid xs={2} item>
                {fndLocation.vouchers.length > 0 && (
                  <div
                    style={{
                      paddingLeft: "6%",
                      paddingTop: "10%",
                      paddingBottom: "10%",
                      color: "white",
                      height: "auto",
                      marginTop: "15%",
                      width: "29vh",
                      backgroundColor: "#e53935",
                      borderRadius: "5px"
                    }}
                  >
                    {fndLocation.vouchers[
                      fndLocation.vouchers.length - 1
                    ].discount_type == "percentage" ? (
                      <span style={{ textShadow: "none" }}>
                        {fndLocation.vouchers[
                          fndLocation.vouchers.length - 1
                        ].discount}
                        % OFF!
                      </span>
                    ) : (
                      <span style={{ textShadow: "none" }}>
                        {fndLocation.currency}.{" "}
                        {fndLocation.vouchers[
                          fndLocation.vouchers.length - 1
                        ].discount}
                        OFF!
                      </span>
                    )}
                    <br />
                    <span style={{ textShadow: "none" }}>
                      Use Promo:{" "}
                      {fndLocation.vouchers[fndLocation.vouchers.length - 1].code}
                    </span>
                  </div>
                )}
                <Button
                  onClick={(e) => openHours()}
                  style={{ color: "white", backgroundColor: "#0f1626", marginTop: "15%" }}
                >
                  {I18nRails.t("Web.view_maps_hours")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
  
  // return pug`
  //   if !!fndLocation
  //     - const backgroundImg = fdLocation.main_image_url()
  //     .half-bg-img(style={marginTop:'5%',backgroundImage: 'linear-gradient(rgba(15, 22, 39, .7),rgba(15, 22, 39, .7)), url('+backgroundImg+')'})
  //       Grid(container style={marginTop:'4%'})
  //         Grid(xs=3 item style={paddingLeft:'3%'})
  //           span#heading_logo
  //             if fndLocation.logo_url
  //                img(alt=fndLocation.name, src=fndLocation.logo_url(), style={ height:'160px', borderRadius:'9px'})
  //         Grid(xs=7 item)
  //           .left-align
  //             h3.white-text ${ fndLocation.name }
  //             if !!fndLocation.addresses
  //               p.white-text ${ fndLocation.address().full_address }
  //             if fndLocation.timeToday()
  //               p.white-text ${ fndLocation.getTime() }
  //             if fndLocation.min_delivery_price
  //               p.white-text ${ I18nRails.t("Web.min_order") }: ${ fndLocation.min_delivery_amount
  //   }
  //             if !!fndLocation.header()
  //               p.white-text ${ "Note: " + fndLocation.header() }
  //             br
  //         Grid(xs=2 item)
  //           if(fndLocation.vouchers.length>0)
  //             div(style={paddingLeft:'6%', paddingTop:'10%', paddingBottom:'10%', color:'white', height:'auto', marginTop:'15%', width:'29vh', backgroundColor:'#e53935', borderRadius:'5px'})
  //               if fndLocation.vouchers[fndLocation.vouchers.length - 1].discount_type == "percentage"
  //                 span(style={textShadow:'none'}) ${ fndLocation.vouchers[
  //     fndLocation.vouchers.length - 1
  //   ].discount
  //   }%  OFF!
  //               else if fndLocation.vouchers[fndLocation.vouchers.length - 1].discount_type != "percentage"
  //                 span(style={textShadow:'none'}) ${ fndLocation.currency
  //   }. ${ fndLocation.vouchers[fndLocation.vouchers.length - 1]
  //     .discount
  //   } OFF!
  //               br
  //               span(style={textShadow:'none'}) Use Promo: ${ fndLocation.vouchers[
  //     fndLocation.vouchers.length - 1
  //   ].code
  //   }
  //           Button(onClick=(e)=>openHours()  style={color:'white',backgroundColor:'#0f1626', marginTop:'15%'}) ${ I18nRails.t(
  //     "Web.view_maps_hours"
  //   ) }
  // `;
};

export default template;
