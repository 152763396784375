import FoodnerdResource from './FoodnerdResource';

export default class OrderResource {
  static get(Rails) {
    const base = `${ Rails.host }/api/orders`;
    const Order = new FoodnerdResource(Rails.access_token, `${ base }/{:id}.json`, { id: ':id' }, {
      update: {
        method: 'PUT'
      },
      recent: {
        method: 'GET',
        url: `${ base }/recent.json`,
        isArray: true
      }
    }
    );

    Order.prototype.isPendingAcceptance = function() {
      return this.state === 'received';
    };

    Order.prototype.isCancelled = function() {
      return this.state === 'cancelled';
    };

    Order.prototype.isCompleted = function() {
      return this.state === 'completed';
    };

    Order.prototype.isAccepted = function() {
      return this.state === 'accepted';
    };

    Order.prototype.isRejected = function() {
      return this.state === 'rejected';
    };

    Order.prototype.isOpen = function() {
      return this.isPendingAcceptance() || this.isAccepted();
    };

    Order.prototype.isClosed = function() {
      return !this.isOpen();
    };

    return Order;
  }
}
