import FoodnerdResource from './FoodnerdResource';
export default class FavItemResource {
  static get(Rails) {
    const mappings = { id: ':id' };
    const baseUrl = `${ Rails.host }/api/favorites`;
    const FavoriteItem = new FoodnerdResource(Rails.access_token, `${ baseUrl }/{:id}.json`, mappings, {
      query: {
        url: `${ baseUrl }.json?obj_type=MenuItem`
      }
    }
    );

    return FavoriteItem;
  }
}
