import AccessTokenFactory from '../services/AccessTokenFactory';
import sweetalert2 from 'sweetalert2';
import ApplicationCacheFactory from "./ApplicationCacheFactory";
import axios from "axios";

export default class AuthFactory {
  constructor(Rails) {
    this.me = this.me.bind(this);
    this.Rails = Rails;
    this.AccessToken = new AccessTokenFactory(Rails);
  }

  me() {
    const headers = { 'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone };
    if (!!this.Rails.access_token) { headers['Authorization'] = `Bearer ${ this.Rails.access_token }`; }
    return axios.get("/api/users/me.json", { headers }).then(
      data => data
      , error => false);
  }

  signup(new_user) {
    return axios.post("/join.json", { user: new_user });
  }

  login(user) {
    return axios.post("/login.json?frontend=1", { user });
  }

  cordovaFacebookLogin(user) {
    const cordovaFacebook = $injector.get('$cordovaFacebook');
    cordovaFacebook.getLoginStatus().then(function(response) {
      if (response.status === 'connected') {
        return axios.post(`${ this.Rails.host }/auth/callbacks/facebook_authenticate`, { access_token: response.authResponse.accessToken, provider: 'facebook' }).then(
          function(response) {
            this.AccessToken.set(response.data.access_token);
            $state.go('show_locations');
          }
          , error => console.log(error));
      } else {
        cordovaFacebook.login(["public_profile", "email"]).then(
          function(response) {
            return axios.post(`${ this.Rails.host }/auth/callbacks/facebook_authenticate`, { access_token: response.authResponse.accessToken, provider: 'facebook' }).then(
              function(response) {
                this.AccessToken.set(response.data.access_token);
                $state.go('show_locations');
              }
              , function(error) { }
            );
          }
          , function(error) {
            console.log("Inside Promise Error");
            console.log(error);
          });
      }
    });
  }

  getFullName() {
    if (!!$localStorage.first_name) {
      return `${ $localStorage.first_name } ${ $localStorage.last_name }`;
    } else {
      return '';
    }
  }

  logout() {
    return axios.delete('/logout.json').then(
      data => {
        this.AccessToken.delete();
      }
      //        ApplicationCacheFactory.clear()
      ,
      function(error) {
        console.log(error);
      });
  }

  isLoggedIn() {
    return !!this.Rails.access_token;
  }
  token() {
    return this.Rails.access_token;
  }
}

// its singleton
