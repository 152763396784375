import FoodnerdResource from './FoodnerdResource';

export default class OfferResource {
  static get(Rails) {
    const Offer = new FoodnerdResource(Rails.access_token, `${ Rails.host }/api/offers.json?country_code={:country_code}'`, { country_code: ':country_code' });
    Offer.prototype.is_general_offer = function() { };

    return Offer;
  }
}
//  TODO: identify select_all and return func or value
//  !!@select_all
