import React from 'react';

import MenuItemCard from './MenuItemCard';

import MenuItemResource from '../resources/menu-item';

import * as rh from './helpers/routes';

import { I18nRails } from '../../shared/rails-i18n-js';
import Grid from '@mui/material/Grid';


const FavoriteMenuItems = props => {
  console.log('this.props.favorites', props.favorites);
  return (
    <Grid container spacing={4} style={{padding:'2%',width:'100%'}}>
      {props.favorites.map((favorite) => (
        <Grid item xs={3} key={`mi+hom${favorite.id}`}>
          <MenuItemCard menu_item={favorite} />
        </Grid>
      ))}
    </Grid>
  );
  // return pug`
  // Grid(container spacing=4 style={padding:'2%',width:'100%'})
  //   each favorite in this.props.favorites
  //     Grid(item xs=3)
  //       MenuItemCard(key='mi+hom'+favorite.id menu_item=favorite, ...this.props)

  // `;
};

export default FavoriteMenuItems;
