import { buildOrderItemFromMenuItem, buildOrderItemFromOrderItem, orderItemAdjustQuantity, orderItemDisplayDescription, orderItemDisplayTitle, orderItemMatchesSelection, orderItemOptionValueDescription, orderItemOptionValueName } from '../models/order_item';
import FoodnerdResource from './FoodnerdResource';

export default class OrderItemResource {
  static get(Rails) {
    const base = `${ Rails.host }/api/order_items`;

    const OrderItem = new FoodnerdResource(Rails.access_token,
      `${ base }/{:id}.json`,
      { id: ':id' },
      {
        update: {
          method: 'PUT',
        },
      }
    );

    OrderItem.fromMenuItem = function(
      menu_item,
      component_option_value,
      menu_item_ingredients,
      quantity,
      location,
      menu
    ) {
      new OrderItem(buildOrderItemFromMenuItem(menu_item, 
        component_option_value, 
        menu_item_ingredients,
        quantity,
        location,
        menu
      ))
    };

    OrderItem.fromOrderItem = function(order_item) {
      //need to provide menu_item_id for order placement
      return new OrderItem(buildOrderItemFromOrderItem(order_item));
    };


    OrderItem.prototype.optionValueName = function() {
      return orderItemOptionValueName(this);
    };

    OrderItem.prototype.optionValueDescription = function() {
      return orderItemOptionValueDescription(this);
    };

    OrderItem.prototype.displayTitle = function() {
      return orderItemDisplayTitle(this);
    };

    OrderItem.prototype.displayDescription = function() {
      return orderItemDisplayDescription(this);
    };

    OrderItem.prototype.matchesSelection = function(
      menu_item,
      component_option_value,
      order_item_ingredients
    ) {
      return orderItemMatchesSelection(this, menu_item, component_option_value, order_item_ingredients);
    };

    OrderItem.prototype.adjustQuantity = function(qty) {
      return orderItemAdjustQuantity(this, qty);
    };

    OrderItem.prototype.displayPrice = function() {
      return orderItemDisplayPrice(this);
    };

    return OrderItem;
  }
}
