import React, { useState, useEffect } from "react";
import { I18nRails } from "../../../shared/rails-i18n-js";
import { Link, useLocation } from "react-router-dom";
import * as rh from "../helpers/routes";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from 'tss-react/mui';
import { alpha, styled } from '@mui/material/styles';

import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { ListItemButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import { resetBasketTotals } from "../Model/basket";
import CartService from "../../services/CartService";
const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${ theme.spacing(4) }px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

const logoStyle = {
  paddingTop: 20,
};
const flexContainer = {
  display: "flex",
  flexDirection: "row",
  padding: 0,
  backgroundColor: "transparent",
  justifyContent: "flex-end",
  marginLeft: "60px",
};

const AppHeader = ({
  Rails,
  basket,
  user,
  handleAppSideNav,
  numberOfCartItems
}) => {
  const [sidebar, setSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [locale, setLocale] = useState("en");

  const domLocation = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSwitcher = (e) => {
    document.cookie = `locale=${ e.target.value }`;
    localStorage.setItem("locale", e.target.value);
    window.location.reload();
  };

  const useStyles = makeStyles()(styles)
  const {classes, cx} = useStyles();
  useEffect(() => {
    try {
      const _locale = JSON.parse(localStorage.getItem("locale"));
      if (!!_locale) {
        setLocale(_locale);
      }
    } catch (e) {
      const _locale = localStorage.getItem("locale");
      if (!!_locale) {
        setLocale(_locale);
      }
    }
  }, []);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 9,
      top: -2,
      border: `2px solid black`,
      padding: "0 1px",
    },
  }));
  resetBasketTotals(basket, CartService.get(Rails));
  const countryConfig = Rails[Rails.current_country];
  let domainlink = Rails.mailto;
  let phone = Rails.telto;

  return (
    <AppBar position="fixed" style={{backgroundColor: '#212121'}}>
      <Grid container spacing={1} style={{padding: '10px 20px', background: '#000'}}>
        <Grid item sm={6} style={{color: '#fff'}}>
          <a href={`mailto:${domainlink}`}>
            <i className="fas fa-envelope"></i>
            <span style={{marginRight: '10px', color: '#fff'}}>{' '+domainlink}</span>
          </a>
          {' '}
          <a href="tel:+92-300-0550831">
            <i className="fas fa-phone"></i>
            <span style={{color: '#fff'}}>{' '+phone}</span>
          </a>
        </Grid>
        <Grid item sm={6} className="languageswitcher">
          <select value={locale} onChange={handleLanguageSwitcher}>
            <option value="en">English</option>
            <option value="ar">Arabic</option>
            <option value="ur">Urdu</option>
          </select>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{padding: '0px 20px'}}>
        <IconButton edge="start" onClick={handleAppSideNav} className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Hidden smDown={true}>
          <Grid item sm={2} style={{textAlign: 'center'}} className={"Item"}>
            <a style={logoStyle} href="/" className="brand-logo main-logo" />
          </Grid>
        </Hidden>
        <Hidden smDown={true}>
          <Grid item sm={9} className={"Item"}>
            <Grid>
              <List component="nav" aria-label="main mailbox folders" style={flexContainer}>
                <ListItem className="hides">
                  {domLocation.pathname === '/' && (
                    <Link to="/locations">
                      <i id="hide" className="fas fa-utensils" />
                      <span className="inNav">{I18nRails.t("merchant.locations")}</span>
                    </Link>
                  )}
                </ListItem>
                <ListItemButton className="hides">
                  <Link to="/blogs">
                    <i className="fas fa-rss" />
                    <span className="inNav">{I18nRails.t("business_dashboard.footer.blog")}</span>
                  </Link>
                </ListItemButton>
                <ListItem className="hides">
                  {basket && basket.location_id ? (
                    <Link
                      style={{cursor: 'pointer'}}
                      to={rh.location_cart({location_id: basket.location_id}, Rails)}
                    >
                      <StyledBadge badgeContent={basket.totals.numberOfCartItems} color="error">
                        <i className="fas fa-shopping-cart" />
                      </StyledBadge>
                      <span className="inNav">{I18nRails.t("Web.shopping.cart")}</span>
                    </Link>
                  ) : (
                    <Link style={{cursor: 'pointer'}} to="/cart">
                      <StyledBadge badgeContent={numberOfCartItems} color="error">
                        <i className="fas fa-shopping-cart" />
                      </StyledBadge>
                      <span style={{textTransform: 'capitalize'}}>{I18nRails.t("Web.shopping.cart")}</span>
                    </Link>
                  )}
                </ListItem>
                {!!user ? (
                  <ListItem className="hides">
                    <Link to='/users/me' style={{cursor: 'pointer'}}>
                      <i className="fas fa-user"></i>
                      <span className="inNav">{user && user.first_name}</span>
                    </Link>
                  </ListItem>
                ) : (
                  <ListItem className="hides">
                    <Link to='/login' style={{cursor: 'pointer'}}>
                      <i className="fas fa-user"></i>
                      <span className="inNav">{I18nRails.t("Web.login")}</span>
                    </Link>
                  </ListItem>
                )}
                <ListItem className="center hides contact-help">
                  <h6>
                    <b>{I18nRails.t("Web.help")}{' '}</b>
                    <a onClick={handleClick}>
                      <i className="fas fa-caret-down"></i>
                    </a>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem>
                        <a href={"mailto:" + countryConfig.email} target="_top">
                          <i className="fas fa-envelope"></i>
                          {"hello@foodnerd.pk"}
                        </a>
                      </MenuItem>
                    </Menu>
                  </h6>
                  <a href={"tel:" + countryConfig.telto} style={{fontSize: '13px'}}>
                    <i className="fas fa-phone phone-call"></i>
                    {countryConfig.telto}
                  </a>                    
                </ListItem>
                {domLocation.pathname === '/' && (
                  <li className="downloadBtn">
                    <Button
                      variant="contained"
                      color="primary"
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.menumonkey.app&hl=en&gl=US"
                      style={{margin: "20px 0px 0px 0px"}}
                      className="white-text button clr-down"
                    >
                      {I18nRails.t("home.get_app")}
                    </Button>
                  </li>
                )}
              </List>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </AppBar>
  );
  // return pug`
  //   // APP BAR MATERIAL UI
  //   AppBar(position="fixed" style={backgroundColor:'#212121'}  )
  //     Grid( container, spacing=1, style={padding:'10px 20px',background:'#000'} )
  //       Grid.Item(item, sm=6, style={color:'#fff'})
  //         a(href='mailto:'+domainlink)
  //           i.fas.fa-envelope 
  //           span(style={marginRight:'10px',color:'#fff'})  ${ domainlink }  
  //         a(href='tel:+92-300-0550831')
  //           i.fas.fa-phone 
  //           span(style={color:'#fff'})  ${ phone }
  //       Grid.Item(item, sm=6, class='languageswitcher')
  //         select(value=${ this.state.locale } onChange=${ this.handleLanguageSwitcher
  //   })
  //           option(value='en') English
  //           option(value='ar') Arabic 
  //           option(value='ur') Urdu
  //     Grid( container, spacing=1, style={padding:'0px 20px'} )
  //       IconButton(edge="start" onClick=this.handleAppSideNav className=classes.menuButton color="inherit" aria-label="menu")
  //         MenuIcon
  //       Hidden(smDown=true)
  //         Grid.Item(item, sm=2, style={textAlign:'center'} )
  //           a.main-logo(style=logoStyle, href='/' className='brand-logo')
  //       Hidden(smDown=true)
  //         Grid.Item(item, sm=9)
  //           Grid
  //           List(component="nav" aria-label="main mailbox folders"  style=flexContainer )
  //             ListItem.hides
  //               #this.isState
  //               if this.props.location.pathname == '/'
  //                 Link(to='/locations')
  //                   i#hide.fas.fa-utensils
  //                   span.inNav ${ I18nRails.t("merchant.locations") }
  //             ListItem(button).hides
  //               Link(to='/blogs')
  //                 i.fas.fa-rss
  //                 span.inNav ${ I18nRails.t("business_dashboard.footer.blog") }
  //             ListItem().hides
  //               if !!this.basket && !!this.basket.location_id
  //                 Link(style={'cursor':'pointer'}, to=rh.location_cart({location_id: this.basket.location_id}, this.Rails))
  //                   StyledBadge(badgeContent=this.basket.totals.numberOfCartItems color="error")
  //                     i.fas.fa-shopping-cart()
  //                   span.inNav ${ I18nRails.t("Web.shopping.cart") }
  //               else
  //                 Link(style={'cursor':'pointer'}, to='/cart')
  //                   StyledBadge(badgeContent=this.numberOfCartItems  color="error")
  //                     i.fas.fa-shopping-cart()
  //                   span(style={'textTransform': 'capitalize'}) ${ I18nRails.t(
  //     "Web.shopping.cart"
  //   ) }
  //             if !!this.state.user
  //               ListItem.hides
  //                 Link(to='/users/me', style={'cursor':'pointer'})
  //                   i.fas.fa-user
  //                   span.inNav #{this.state.user && this.state.user.first_name}
  //             else
  //               ListItem.hides
  //                 Link(to='/login', style={'cursor':'pointer'})
  //                   i.fas.fa-user
  //                   span.inNav ${ I18nRails.t("Web.login") }
  //             ListItem.center.hides.contact-help
  //               h6
  //                 b ${ I18nRails.t("Web.help") }  
  //                 a(onClick=${ this.handleClick }) 
  //                     i.fas.fa-caret-down
  //                 Menu( id="simple-menu" anchorEl=${ this.state.anchorEl
  //   } keepMounted open=${ Boolean(
  //     this.state.anchorEl
  //   ) } onClose=${ this.handleClose })
  //                   MenuItem 
  //                     a(href="mailto:"+countryConfig.email, target="_top")
  //                       i.fas.fa-envelope
  //                       = "hello@foodnerd.pk"    
  //               a(href="tel:"+countryConfig.telto style={fontSize:'13px'})
  //                 i.fas.fa-phone.phone-call 
  //                 = countryConfig.telto
  //             if this.props.location.pathname == '/'
  //               li.downloadBtn
  //                 Button.white-text.button.clr-down(variant="contained" color="primary"  target="_blank" href="https://play.google.com/store/apps/details?id=com.menumonkey.app&hl=en&gl=US" style={margin: "20px 0px 0px 0px"}) ${ I18nRails.t(
  //     "home.get_app"
  //   ) }

  // `;
};

export default AppHeader;
