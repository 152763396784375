import { I18nRails } from "../../shared/rails-i18n-js";

export const getMenuItemOffer = (menu_item) => {
  if (!!menu_item.offers) {
    if (menu_item.offers.length > 0) {
      var offer = menu_item.offers[0];
      if (!!offer.flat_discount) {
        let amount = I18nRails.numberToCurrency(offer.flat_discount, {
          unit: basket0.location.currency_symbol,
          stripInsignificantZeros: true,
        });
        offer = `Flat ${ amount } off`;
      } else if (!!offer.percentage_discount) {
        offer = `${ offer.percentage_discount }% off`;
      }
      return offer;
    }
  }
  return null;
}

export const menuItemHasRequiredOptions = (menu_item) => {
  if (!!menu_item) {
    if (
      !!menu_item.component_option_values &&
      menu_item.component_option_values.length > 0
    ) {
      if (
        !!menu_item.component_option_values[0].available_option_values &&
        menu_item.component_option_values[0].available_option_values.length >
        1
      ) {
        return true;
      } else if (
        !!menu_item.ingredient_lists &&
        menu_item.ingredient_lists.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

export const menuItemHasDescription = (menu_item) => {
  if (!!menu_item) {
    return !!menu_item.description && menu_item.description.length > 0;
  }
}

export const menuItemImage = function(menuItem) {
  return menuItem.url_image1;
};

export const menuItemBasePrice = function(menuItem) {
  if (!!menuItem.price) { return menuItem.price; }

  let returnPrice = 0;
  for (let component_option_value of menuItem.component_option_values) {
    for (let available_option_value of component_option_value.available_option_values) {
      if ((returnPrice <= 0) || (returnPrice > parseFloat(available_option_value.price))) { returnPrice = parseFloat(available_option_value.price); }
    }
  }
  return returnPrice;
};

export const menuItemSmall_logo_url = function(menuItem, Rails) {
  if (!!menuItem.hosted_datas && (menuItem.hosted_datas.length > 0) && !!menuItem.hosted_datas[menuItem.hosted_datas.length - 1].url_small_logo) {
    menuItem.hosted_datas[menuItem.hosted_datas.length - 1].url_small_logo.replace(/development/, 'production');
  }

  return Rails.default_location_image_path.replace(/development/, 'production');
};

export const menuItemDisplayPrice = function(menuItem) {
  let total_price_for_ingredients = 0;
  const option_price = menuItem.selected_option ? parseFloat(menuItem.selected_option.price) : menuItemBasePrice(menuItem);
  if (menuItem.selected_ingredients) {
    for (let ingredient of menuItem.selected_ingredients) {
      total_price_for_ingredients += parseFloat(ingredient.delta_price);
    }
  }

  return total_price_for_ingredients + option_price;
};

export const menuItemCalculatedPrice = function(menuItem) {
  let total_price_for_ingredients = 0;
  const option_price = menuItem.selected_option ? menuItem.selected_option.price : menuItem.price;
  if (menuItem.selected_ingredients) {
    for (let ingredient of menuItem.selected_ingredients) {
      total_price_for_ingredients += parseFloat(ingredient.delta_price);
    }
  }
  return total_price_for_ingredients + parseFloat(option_price);
};

export const menuItemAvailableOptionValues = function(menuItem) {
  if (!menuItem.available_option_values) {
    menuItem.available_option_values = [];
    for (let component_option_value of menuItem.component_option_values) {
      for (let available_option_value of component_option_value.available_option_values) {
        menuItem.available_option_values.push(available_option_value);
      }
    }
  }
  return menuItem.available_option_values;
};