export const getMenuOffer = (menu) => {
  if (!!menu.offers) {
    if (menu.offers.length > 0) {
      var offer = menu.offers[0];
      if (!!offer.flat_discount) {
        const amount = I18nRails.numberToCurrency(offer.flat_discount, {
          unit: basket0.location.currency_symbol,
          stripInsignificantZeros: true,
        });
        offer = `Flat ${ amount } off`;
      } else if (!!offer.percentage_discount) {
        offer = `${ offer.percentage_discount }% off`;
      }
      return offer;
    }
  }
  return null;
}
