import FoodnerdResource from './FoodnerdResource';
import FavoriteService from "../services/FavoriteService";
import ApplicationCacheFactory from "../services/ApplicationCacheFactory";
import { isUserLiked, processUserFavorites, setUserFriendsFavorites, userFriendsWhoLikeLocation } from '../models/user';

//import defaults from 'superagent-defaults'
//superagent = defaults({'Authorization', 'Bearer---'})

export default class UserResource {
  static get(Rails) {
    const User = new FoodnerdResource(Rails.access_token, `${ Rails.host }/api/users/{:id}.json`, {
      id: ":id",
    });

    User.prototype.full_name = function() {
      return userFullname(this);
    };

    User.prototype.admin = function() {
      return isUserAdmin(this);
    };

    User.prototype.isManager = function(location) {
      return isUserManager(this, location);
    };

    User.prototype.user_likes = function() {
      return userLikes(this);
    };

    User.prototype.user_friends_likes = function() {
      return userFriendsLikes(this);
    };

    User.prototype.processUserFavorites = function() {
      return processUserFavorites(this);
    };

    User.prototype.setFriendsFavorites = function(friends_favorites) {
      return setUserFriendsFavorites(this, friends_favorites);
    };

    User.prototype.setLocationsFriends = function(locations_friends) {
      return setUserLocationsFriends(this, locations_friends);
    };

    User.prototype.friendsWhoLikeLocation = function(location) {
      return userFriendsWhoLikeLocation(this, location);
    };

    User.prototype.getFriendsWhoLikeMenu = function(menu) {
      return userFriendsLikes(this)["Menu"][menu.id]["friends"];
    };

    User.prototype.getFriendsWhoLikeMenuItem = function(menu_item) {
      return userFriendsLikes(this)["MenuItem"][menu_item.id]["friends"];
    };

    User.prototype.friends_likes_for_location = function(location) {
      return getUserFriendsLikesForLocation(this, location);
    };

    User.prototype.friends_likes_for_menu = function(menu) {
      return getUserFriendsLikesForMenu(this, menu);
    };

    User.prototype.friends_likes_for_menu_item = function(menu_item) {
      return getUserFriendsLikesForMenuItem(this, menu_item)
    };

    User.prototype.is_liked = function(likeable, likeable_type) {
      return isUserLiked(this, likeable, likeable_type)
    };

    User.prototype.like = function(likeable, likeable_type) {
      return setUserLike(this, likeable, likeable_type)
    };

    User.prototype.unlike = function(likeable, likeable_type) {
      return setUserUnlike(this, likeable, likeable_type);
    };

    User.prototype.avatar = function() {
      return userAvatar(this)
    };

    User.prototype.tokenSet = function() {
      return isUserTokenSet(this)
    };

    User.prototype.tokenSaved = function() {
      return isUserTokenSaved(this)
    };

    return User;
  }
}
