import FoodnerdResource from './FoodnerdResource';

export default class DeliveriesResource {
  static get(Rails) {

    return new FoodnerdResource(Rails.access_token, `${ Rails.host }/locations/{:location_id}/deliveries.json`, {
      location_id: ':location_id'
    }
    );
  }
}
