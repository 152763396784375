import FoodnerdResource from './FoodnerdResource';

export default class UserPhoneResource {
  static get(Rails) {
    const base = `${ Rails.host }/users/me`;
    const mappings = { id: ':id' };
    const UserPhone = new FoodnerdResource(Rails.access_token, `${ base }/phones.json`, mappings, {
      save: {
        url: `${ base }/phones.json`,
        method: 'POST'
      }
    });

    return UserPhone;
  }
}
