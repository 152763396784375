import { menuItemAvailableOptionValues, menuItemBasePrice, menuItemCalculatedPrice, menuItemDisplayPrice, menuItemImage, menuItemSmall_logo_url } from '../models/menu_item';
import FoodnerdResource from './FoodnerdResource';

export default class MenuItemResource {
  static get(Rails) {
    const base = `${ Rails.host }/api/menu_items`;
    const MenuItem = new FoodnerdResource(
      Rails.access_token, 
      `${ base }/{:id}.json`, 
      { id: ':id' }, 
      {
        update: {
          method: 'PUT'
        },
        get: {
          method: 'Get'
        }
      }
    );
    //        cache: ApplicationCacheFactory.get()

    MenuItem.prototype.image = function() {
      return menuItemImage(this);
    };

    MenuItem.prototype.basePrice = function() {
      return menuItemBasePrice(this)
    };

    MenuItem.prototype.small_logo_url = function() {
      return menuItemSmall_logo_url(this, Rails)
    };

    MenuItem.prototype.displayPrice = function() {
      return menuItemDisplayPrice(this);
    };

    MenuItem.prototype.calculatedPrice = function() {
      return menuItemCalculatedPrice(this);
    };

    MenuItem.prototype.availableOptionValues = function() {
      return menuItemAvailableOptionValues(this);
    };


    return MenuItem;
  }
}
