import { format, utcToZonedTime } from "date-fns-tz";
import { I18nRails } from "../../shared/rails-i18n-js";
import { isSameDay } from "date-fns";

export const location_friends_likes =  (location, user) => {
  return getUserFriendsLikesForLocation(user, location);
};
export const location_like =  (location, user) => {
  return setUserLike(user, location, "Location");
};
export const location_unlike =  (location, user) => {
  return setUserUnLike(user, location, "Location");
};
export const is_location_liked_by =  (location, user) => {
  return isUserLiked(user, location, "Location");
};

export const location_logo_url =  (location, Rails) => {
  if (
    !!location.hosted_datas &&
    location.hosted_datas.length > 0 &&
    !!location.hosted_datas[location.hosted_datas.length - 1].url_logo
  ) {
    return location.hosted_datas[location.hosted_datas.length - 1].url_logo.replace(
      /development/,
      "production"
    );
  }

  return Rails.default_logo_path.replace(/development/, "production");
};

export const location_img_thumb_url =  (location) => {
  //    @img_url = @img_url.img_url.replace(/development/, 'production')
  return location.img_url.img_url.thumb.url;
};

export const location_img_email_url =  (location) => {
  return location.img_url.img_url.email.url;
};

export const location_small_logo_url =  (location, Rails) => {
  if (
    !!location.hosted_datas &&
    location.hosted_datas.length > 0 &&
    !!location.hosted_datas[location.hosted_datas.length - 1].url_small_logo
  ) {
    return location.hosted_datas[location.hosted_datas.length - 1].url_small_logo;
  }
  return Rails.default_logo_path.replace(/development/, "production");
};

export const location_card_logo_url =  (location) => {
  if (
    !!location.hosted_datas &&
    location.hosted_datas.length > 0 &&
    !!location.hosted_datas[location.hosted_datas.length - 1].url_card_logo
  ) {
    return location.hosted_datas[
      location.hosted_datas.length - 1
    ].url_card_logo.replace(/development/, "production");
  }

  return Rails.default_logo_path.replace(/development/, "production");
};

export const location_sidebar_logo_url =  (location) => {
  if (
    !!location.hosted_datas &&
    location.hosted_datas.length > 0 &&
    !!location.hosted_datas[location.hosted_datas.length - 1].url_sidebar_logo
  ) {
    return location.hosted_datas[
      location.hosted_datas.length - 1
    ].url_sidebar_logo.replace(/development/, "production");
  }

  return Rails.default_logo_path.replace(/development/, "production");
};

export const location_main_image_url =  (location) => {
  if (
    !!location.hosted_datas &&
    location.hosted_datas.length > 0 &&
    !!location.hosted_datas[location.hosted_datas.length - 1].url_main_image
  ) {
    if (
      location.hosted_datas[location.hosted_datas.length - 1].url_main_image !==
      "res-cover.jpg"
    ) {
      return location.hosted_datas[
        location.hosted_datas.length - 1
      ].url_main_image.replace(/development/, "production");
    }
  }
  return location_logo_url(location);
};
//Rails.default_location_main_image_path

export const location_header =  (location) => {
  if (
    !!location?.hosted_datas &&
    location?.hosted_datas.length > 0 &&
    !!location?.hosted_datas[location.hosted_datas.length - 1].data
  ) {
    return location.hosted_datas[location.hosted_datas.length - 1].data.header;
  }
  return "";
};

export const location_future_orders_activated =  (location) => {
  if (
    !!location.hosted_datas &&
    location.hosted_datas.length > 0 &&
    !!location.hosted_datas[location.hosted_datas.length - 1].data
  ) {
    return location.hosted_datas[location.hosted_datas.length - 1].data
      .future_orders_activated;
  }
};

export const location_address =  (location) => {
  return (
    (!!location.addresses && location.addresses.length > 0 && location.addresses[0]) ||
    ""
  );
};

export const getLocationDistance =  (location, coords) => {
  const R = 6371; // Radius of the earth in km
  const location_coords = location_address(location);

  if (
    !location_coords ||
    !coords ||
    (coords.lat === 0 && coords.lng === 0)
  ) {
    return "";
  }

  const dLat = (coords.lat - location_coords.lat) * (Math.PI / 180);
  const dLng = (coords.lng - location_coords.lng) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(location_coords.lat * (Math.PI / 180)) *
    Math.cos(coords.lat * (Math.PI / 180)) *
    Math.sin(dLng / 2) *
    Math.sin(dLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return `${ Math.round(d).toString() } km`;
};

export const isLocationOnlineOrdersActive =  (location) => {
  if (!!location.online_booking && location.online_booking === "active") {
    return true;
  } else {
    return false;
  }
};

export const location_check_same_charges =  (location) => {
  return location.min_delivery_price === location.max_delivery_price;
};

export const location_check_range =  (location) => {
  if (location.min_delivery_price === location.max_delivery_price) {
    return false;
  }
  if (!!location.min_delivery_price && !!location.max_delivery_price) {
    return true;
  }
  if (location.min_delivery_price === 0) {
    return true;
  }
};

export const locationHasOffers =  (location) => {
  if (!!location.offers) {
    if (location.offers.length > 0) {
      return true;
    } else {
      return false;
    }
  }
};

export const locationHasDelivery =  (location) => {
  if (!location.channels || location.channels.length < 1) {
    return false;
  }

  for (let channel of location.channels) {
    if (channel.name === "delivery") {
      return true;
    }
  }
  return false;
};

export const localizedLocationWeeklySchedules =  (location) => {
  if (!!location._weeklySchedules) {
    return location._weeklySchedules;
  }
  if (!location.weekly_schedules || location.weekly_schedules.length < 1) {
    return {};
  }
  const sorted_weekly_schedules = location.weekly_schedules.sort(function(
    s1,
    s2
  ) {
    if (s1.day_of_week === s2.day_of_week) {
      const st1 = new Date(s1.start_time);
      const st2 = new Date(s2.start_time);
      if (st1 > st2) {
        return -1;
      } else if (st2 > st1) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return s1.day_of_week - s2.day_of_week;
    }
  });

  const weeklySchedules = {};
  for (let schedule of sorted_weekly_schedules) {
    if (!!schedule.start_time && !!schedule.end_time && schedule.open) {
      const startTime = new Date(schedule.start_time);
      const endTime = new Date(schedule.end_time);

      let dayOfWeek = schedule.day_of_week;
      // if (startTime.getDay() !== startTimeOriginal.getDay()) {
      //   if (startTime.getDay() < startTimeOriginal.getDay()) {
      //     dayOfWeek -= 1;
      //   } else {
      //     dayOfWeek += 1;
      //   }
      // }

      const index = dayOfWeek % 7;
      if (!weeklySchedules[index]) {
        weeklySchedules[index] = [];
      }
      weeklySchedules[index].push({
        start: startTime,
        end: endTime,
        open: schedule.open,
      });
    }
  }
  //          weeklySchedules[index] = {'start': startTime.toDate(), 'end': endTime.toDate()}
  location._weeklySchedules = weeklySchedules;
  return location._weeklySchedules;
};

export const locationOperationHours =  (location) => {
  if (!!location._operationHours) {
    return location._operationHours;
  }
  const weeklyScheduless = localizedLocationWeeklySchedules(location);

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  location._operationHours = [];
  if (weeklyScheduless.length < dayNames.length) {
    return location._operationHours;
  }
  for (let index = 0; index < dayNames.length; index++) {
    const dayName = dayNames[index];
    const weeklySchedules = weeklyScheduless[index];
    if (!!weeklySchedules && weeklySchedules.length > 0) {
      for (let weeklySchedule of weeklySchedules) {
        if (!!weeklySchedule.start) {
          location._operationHours.push(
            `${ dayName } ${ I18nRails.l(
              "time.formats.short_time_only",
              weeklySchedule.start
            ) } - ${ I18nRails.l(
              "time.formats.short_time_only",
              weeklySchedule.end
            ) }`
          );
        }
      }
    } else {
      location._operationHours.push(`${ dayName } Closed`);
    }
  }
  return location._operationHours;
};

export const initLocationOpeningHours =  (location) => {
  let ts;
  if (!location.weekly_schedules || location.weekly_schedules.length < 1) {
    return;
  }
  const weeklySchedules = localizedLocationWeeklySchedules(location);
  const today = new Date();
  const day = today.getDay();

  if (!weeklySchedules[day] || !weeklySchedules[day].open) {
    location._timeToday = "";
    location._openNow = false;
  } else {
    //@_timeToday= "#{I18nRails.l('time.formats.short_time_only', weeklySchedules[day].start)} to #{I18nRails.l('time.formats.short_time_only', @_weeklySchedules[day].end)}"
    location._openNow = false;
    location._timeToday = "";
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const minutesOfDay = minutes + hours * 60;
    for (let dailySchedule of weeklySchedules[day]) {
      if (!location._openNow) {
        const startMinutesOfDay =
          dailySchedule.start.getMinutes() +
          dailySchedule.start.getHours() * 60;
        const endMinutesOfDay =
          dailySchedule.end.getMinutes() +
          dailySchedule.end.getHours() * 60;
        //account for end time going into next day
        //if (minutesOfDay >= startMinutesOfDay) && (today.getDay() > dailySchedule.end.getDay() || minutesOfDay <= endMinutesOfDay)
        if (
          (dailySchedule.open &&
            startMinutesOfDay <= endMinutesOfDay &&
            minutesOfDay >= startMinutesOfDay &&
            minutesOfDay <= endMinutesOfDay) ||
          (startMinutesOfDay > endMinutesOfDay &&
            (minutesOfDay >= startMinutesOfDay ||
              minutesOfDay <= endMinutesOfDay))
        ) {
          location._openNow = true;
          ts = I18nRails.l(
            "time.formats.short_time_only",
            dailySchedule.end
          );
          location._timeToday = I18nRails.t("client.locations.open-text", {
            time: ts,
          });
        } else if (dailySchedule.open && minutesOfDay < startMinutesOfDay) {
          //opens today at startMinutesOfDay
          location._openNow = false;
          ts = I18nRails.l(
            "time.formats.short_time_only",
            dailySchedule.start
          );
          location._timeToday = I18nRails.t(
            "client.locations.closed-text-today",
            { time: ts }
          );
        }
      }
    }
  }

  if (!location._timeToday) {
    let days = 1;
    while (!location._timeToday && days < 7) {
      const nextDay = (day + days) % 7;
      if (!!weeklySchedules[nextDay]) {
        if (days === 1) {
          ts = I18nRails.l(
            "time.formats.short_time_only",
            weeklySchedules[nextDay][0].start
          );
          location._timeToday = I18nRails.t(
            "client.locations.closed-text-tomorrow",
            { time: ts }
          );
        } else {
          ts = I18n.l(
            "time.formats.short_day",
            weeklySchedules[nextDay][0].start
          );
          location._timeToday = I18n.t("client.locations.closed-text-week", {
            default: "Opens",
            time: ts,
          });
        }
      }
      days += 1;
    }
    if (!location._timeToday) {
      // ts = I18n.l(future_on_time.in_time_zone(client_time_zone), format: :future_day)
      // @_timeToday = I18n.t('client.locations.closed-text-longer', default: 'Opens', time: ts)
      return (location._timeToday = I18n.t("location.closed"));
    }
  }
};

export const isLocationOpenNow =  (location) => {
  //return true;
  const weeklySchedules = localizedLocationWeeklySchedules(location);
  const today = new Date();
  const day = today.getDay();
  if (!weeklySchedules[day]) {
    return false;
  } else {
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const minutesOfDay = minutes + hours * 60;
    for (let dailySchedule of weeklySchedules[day]) {
      const startMinutesOfDay =
        dailySchedule.start.getMinutes() +
        dailySchedule.start.getHours() * 60;
      let endMinutesOfDay =
        dailySchedule.end.getMinutes() + dailySchedule.end.getHours() * 60;
      if (
        (dailySchedule.open &&
          startMinutesOfDay <= endMinutesOfDay &&
          minutesOfDay >= startMinutesOfDay &&
          minutesOfDay <= endMinutesOfDay) ||
        (startMinutesOfDay > endMinutesOfDay &&
          (minutesOfDay >= startMinutesOfDay ||
            minutesOfDay <= endMinutesOfDay))
      ) {
        return true;
      }
    }
  }
  return false;
};

export const locationTimeToday =  (location) => {
  if (!!location._timeToday) {
    return location._timeToday;
  }
  initLocationOpeningHours(location);
  return location._timeToday;
};

export const getLocationTime =  (location) => {
  let obj = location?.ontimes ?? null;

  let array = obj.map((ele) => {
    let robj = {};
    robj["startTime"] = utcToZonedTime(
      ele[0],
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    robj["endTime"] = utcToZonedTime(
      ele[1],
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    return robj;
  });

  if (array) {
    let current = new Date();
    for (let i = 0; i < array.length; i++) {
      if (isSameDay(array[i].startTime, current)) {
        let startTime = array[i].startTime;
        let endTime = array[i].endTime;

        if (current > startTime && current < endTime) {
          let endString = format(endTime, "hh:mm a");
          return `${ I18nRails.t(
            "client.location.open_status"
          ) } ${ endString }`;
        } else {
          let startString = format(startTime, "hh:mm a");
          return `${ I18nRails.t(
            "client.location.closed_status"
          ) } ${ startString }`;
        }
      } else {
        return `${ I18nRails.t("client.location.closed") }`;
      }
    }
  }
};