import FoodnerdResource from './FoodnerdResource';

export default class VoucherResource {
  static get(Rails) {
    const baseUrl = `${ Rails.host }/api/vouchers`;

    return new FoodnerdResource(Rails.access_token, `${ baseUrl }/{:id}.json`, { id: ':id' }, {
      verify: {
        method: 'GET',
        url: `${ baseUrl }/verify`
      }
    }
    );
  }
}

