import { SUPPORTED_CITIES } from "../../constants";

export const isLoggedIn = (Rails) => {
  return !!Rails.access_token;
}

export const isAdmin = (Rails) => {
  return !!Rails.is_admin;
};

export const loadImage = (image) => {
  return require(`../../../images/${image}`);
};

export const itunes_app_url_with_fallback = (Rails) => {
  return Rails.itunes_app_url_with_fallback;
};

export const google_play_app_url_with_fallback = (Rails) => {
  return Rails.google_play_app_url_with_fallback;
};

export const itunes_business_app_url_with_fallback = (Rails) => {
  return Rails.itunes_business_app_url_with_fallback;
};

export const google_play_business_app_url_with_fallback = (Rails) => {
  return Rails.google_play_business_app_url_with_fallback;
};

export const getCityKeyValues = () => {
  return SUPPORTED_CITIES.map((city, index) => ({
    key: index,
    value: city.toLowerCase(),
    display: city,
  }));
};

export const getLocationId = (Rails, params) => {
  let location_id;
  if (
    !!Rails &&
    Rails.domain_place_type === "Location" &&
    !!Rails.domain_place_id
  ) {
    location_id = Rails.domain_place_id;
  } else {
    if (params && params.location_id) {
      location_id = params.location_id;
    }
  }

  return location_id;
};