import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { I18nRails } from "../../shared/rails-i18n-js";

const NotificationSettings = ({
  handleSwitchChange,
  notificationSettings,
  updateNotificationSettings
}) => {

  const [notifications, setNotifications] = useState();

  const handleChange = (e) => {
    handleSwitchChange(e);
  };

  const saveNotificationSettings = () => {
    updateNotificationSettings(notifications);
  };

  return <>
    <Grid container id="notificationGrid">
      <Grid item xs={12}>
        <h5 style={{ color: 'grey', marginBottom: 15, marginTop: 10, textTransform: 'uppercase' }}>{I18nRails.t("Web.push_notifications")}</h5>
        {notificationSettings.slice(0, 4).map((object, i) =>
          <Grid xs={12} item key={i}>
            <Switch
              checked={object.value}
              color="primary"
              onChange={handleChange}
              name={object.name}
            />
            <label style={{ color: 'grey', fontSize: '13px' }}>
              {object.label}
            </label>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <h5 style={{ color: 'grey', marginBottom: 15, marginTop: 10, textTransform: 'uppercase' }}>{I18nRails.t("Web.email_notifications")}</h5>
        {notificationSettings.slice(4, 8).map((object, i) =>
          <Grid item xs={12} key={i}>
            <Switch
              checked={object.value}
              color="primary"
              key={i}
              onChange={handleChange}
              name={object.name}
            />
            <label style={{ color: 'grey', fontSize: '13px' }}>
              {object.label}
            </label>
          </Grid>

        )}
      </Grid>
      <Grid item xs={12}>
        <h5 style={{ color: 'grey', marginBottom: 15, marginTop: 10, textTransform: 'uppercase' }}>{I18nRails.t("Web.sms_notifications")}</h5>
        {notificationSettings.slice(8, 12).map((object, i) =>
          <Grid item xs={12} key={i}>
            <Switch
              checked={object.value}
              color="primary"
              key={i}
              onChange={handleChange}
              name={object.name}
            />
            <label style={{ color: 'grey', fontSize: '13px' }}>
              {object.label}
            </label>
          </Grid>

        )}
      </Grid>
      <Grid item xs={12}>
        <Button onClick={() => saveNotificationSettings()} style={{ color: 'white', backgroundColor: '#ff5330', marginTop: 15 }}>{I18nRails.t('client.profile.notifications_save')}</Button>
      </Grid>
    </Grid>
  </>;
  // return pug`
  //   ${ <>
  //     <Grid container id="notificationGrid">
  //       <Grid item xs={12}>
  //         <h5 style={{ color: 'grey', marginBottom: 15, marginTop: 10, textTransform: 'uppercase' }}>{I18nRails.t("Web.push_notifications")}</h5>
  //         {this.notificationSettings.slice(0, 4).map((object, i) =>
  //           <Grid xs={12} item key={i}>
  //             <Switch
  //               checked={object.value}
  //               color="primary"
  //               onChange={this.handleChange}
  //               name={object.name}
  //             />
  //             <label style={{ color: 'grey', fontSize: '13px' }}>
  //               {object.label}
  //             </label>
  //           </Grid>
  //         )}
  //       </Grid>
  //       <Grid item xs={12}>
  //         <h5 style={{ color: 'grey', marginBottom: 15, marginTop: 10, textTransform: 'uppercase' }}>{I18nRails.t("Web.email_notifications")}</h5>
  //         {this.notificationSettings.slice(4, 8).map((object, i) =>
  //           <Grid item xs={12} key={i}>
  //             <Switch
  //               checked={object.value}
  //               color="primary"
  //               key={i}
  //               onChange={this.handleChange}
  //               name={object.name}
  //             />
  //             <label style={{ color: 'grey', fontSize: '13px' }}>
  //               {object.label}
  //             </label>
  //           </Grid>

  //         )}
  //       </Grid>
  //       <Grid item xs={12}>
  //         <h5 style={{ color: 'grey', marginBottom: 15, marginTop: 10, textTransform: 'uppercase' }}>{I18nRails.t("Web.sms_notifications")}</h5>
  //         {this.notificationSettings.slice(8, 12).map((object, i) =>
  //           <Grid item xs={12} key={i}>
  //             <Switch
  //               checked={object.value}
  //               color="primary"
  //               key={i}
  //               onChange={this.handleChange}
  //               name={object.name}
  //             />
  //             <label style={{ color: 'grey', fontSize: '13px' }}>
  //               {object.label}
  //             </label>
  //           </Grid>

  //         )}
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Button onClick={() => this.saveNotificationSettings()} style={{ color: 'white', backgroundColor: '#ff5330', marginTop: 15 }}>{I18nRails.t('client.profile.notifications_save')}</Button>
  //       </Grid>
  //     </Grid>
  //   </>
  //   }
  // `;
};

export default NotificationSettings;

