import React, { useState, useEffect } from "react";
import { GoogleMapProvider, MapBox, Marker } from "@googlemap-react/core";

import { I18nRails } from "../../shared/rails-i18n-js";

import AppLayout from "./AppLayout";

import AddressesComponent from "./AddressesComponent";
import AddressComponent from "./AddressComponent";
import _loginOrSignup from "./templates/_loginOrSignup";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { withLocation } from "../../react-pos/components/withLocation";
import { withParams } from "../../react-pos/components/withParams";
import withParent from "./hoc/withParent";
import withAddresses from "./hoc/withAddresses";

const AppUserAddresses = ({
  Rails,
  loadUser,
  user,
  destroyAddress,
  setDefaultAddress,
  addAddress,
  cancelAddress,
  updateAddress,
  enterNewAddress,
  setLatLng,
  latLng,
  ...others
}) => {
  const [owner, setOwner] = useState();
  const [newAddress, setNewAddress] = useState();
  const [unmappedAddress, setUnmappedAddress] = useState();
  const [_unmappedAddress, set_unmappedAddress] = useState();

  useEffect(() => {
    if (!user) {
      loadUser((user) => {
        setOwner(user);
      });
    } else {
      setOwner(user);
    }
  }, []);
  return (
    <AppLayout
      Rails={Rails}
      user={user}
      loadUser={loadUser}
      destroyAddress={destroyAddress}
      setDefaultAddress={setDefaultAddress}
      addAddress={addAddress}
      cancelAddress={cancelAddress}
      updateAddress={updateAddress}
      enterNewAddress={enterNewAddress}
      setLatLng={setLatLng}
      latLng={latLng}
      {...others}
    >
      {!!user ? (
        <Grid item xs={12}>
          <p style={{ marginTop: "8%", marginLeft: "2%", fontWeight: "bold" }}>
            Your Addresses
          </p>
          <List style={{ width: "100%" }}>
            {user.addresses.map((address, index) => (
              <ListItem key={"hom-" + index}>
                <Accordion
                  style={{
                    marginBottom: "2%",
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container>
                      <Grid xs={8} item>
                        <span style={{ fontWeight: "bold" }}>
                          {address.name_door}
                        </span>
                        <br />
                        {address.street}, {address.street2},
                        <br />
                        {address.city} {address.zip}
                      </Grid>
                      {address.default_address ? (
                        <Grid xs={2} item>
                          <span>Default Address</span>
                          <IconButton
                            aria-label="delete"
                            style={{ color: "#ff6961" }}
                            onClick={(e) =>
                              destroyAddress.current(address, e, true)
                            }
                          >
                            <DeleteIcon fontSize="medium" />
                          </IconButton>
                        </Grid>
                      ) : (
                        <Grid xs={2} item>
                          <Button
                            onClick={(e) =>
                              setDefaultAddress.current(address, e)
                            }
                            style={{
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                            className="btn-second"
                          >
                            Set Default
                          </Button>
                          <IconButton
                            aria-label="delete"
                            style={{ color: "#ff6961" }}
                            onClick={(e) =>
                              destroyAddress.current(address, e, true)
                            }
                          >
                            <DeleteIcon fontSize="medium" />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AddressComponent
                      address={address}
                      addAddress={addAddress.current}
                      cancelAddress={cancelAddress.current}
                      updateAddress={updateAddress.current}
                      Rails={props.Rails}
                    />
                  </AccordionDetails>
                </Accordion>
              </ListItem>
            ))}
          </List>
          <Grid item xs={5}>
            <Button
              className="btn-first order-mode-btn"
              style={{ marginLeft: "3%", marginBottom: "5%" }}
              onClick={(e) => enterNewAddress.current()}
            >
              {I18nRails.t("client.progress.addresses.new")}
            </Button>
          </Grid>
          {!!newAddress && (
            <AddressComponent
              address={{}}
              addAddress={addAddress.current}
              cancelAddress={cancelAddress.current}
              updateAddress={updateAddress.current}
              Rails={props.Rails}
            />
          )}
          {!!unmappedAddress && (
            <>
              <Grid item xs={12}>
                <GoogleMapProvider />
                <MapBox
                  apiKey={process.env.GOOGLE_GEOCODE_API_KEY}
                  opts={{
                    center: {
                      lat: (!!latLng && !!latLng.lat && !!latLng.lng
                        ? latLng
                        : unmappedAddress
                      ).lat,
                      lng: (!!latLng && !!latLng.lat && !!latLng.lng
                        ? latLng
                        : unmappedAddress
                      ).lng,
                    },
                    zoom: 12,
                  }}
                  onClick={(e) => setLatLng0.current(e, unmappedAddress)}
                />
                <Marker
                  opts={{
                    label: "Identify Address",
                    draggable: true,
                    position: {
                      lat: unmappedAddress.lat,
                      lng: unmappedAddress.lng,
                    },
                  }}
                  onDragend={(e) => setLatLng0.current(e, unmappedAddress)}
                />
                <span>
                  lat: {unmappedAddress.lat}, lng: {unmappedAddress.lng}
                </span>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className="mobile-cancel-btn btn-first btn"
                  onClick={(e) => cancelAddress.current(unmappedAddress)}
                >
                  {I18nRails.t("client.progress.addresses.cancel")}
                </Button>
              </Grid>
            </>
          )}
          {!!_unmappedAddress && (
            <div className="row choose-map">
              <GoogleMapProvider />
              <MapBox
                apiKey={process.env.GOOGLE_GEOCODE_API_KEY}
                opts={{
                  center: {
                    lat: (!!latLng && !!latLng.lat && !!latLng.lng
                      ? latLng
                      : _unmappedAddress
                    ).lat,
                    lng: (!!latLng && !!latLng.lat && !!latLng.lng
                      ? latLng
                      : _unmappedAddress
                    ).lng,
                  },
                  zoom: 12,
                }}
                onClick={(e) => setLatLng0.current(e, _unmappedAddress)}
              />
              <Marker
                opts={{
                  label: "Identify Address",
                  draggable: true,
                  position: {
                    lat: _unmappedAddress.lat,
                    lng: _unmappedAddress.lng,
                  },
                }}
                onDragend={(e) => setLatLng0.current(e, _unmappedAddress)}
              />
              <span>
                lat: {_unmappedAddress.lat}, lng: {_unmappedAddress.lng}
              </span>
            </div>
          )}
        </Grid>
      ) : (
        _loginOrSignup(
          "Login to your existing account or Sign Up.",
          props.location
        )
      )}
    </AppLayout>
  );
};

export default withParent(withAddresses(AppUserAddresses));
