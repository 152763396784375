let DEBUG = false;
let API_KEY = process.env.GOOGLE_GEOCODE_API_KEY;
let LANGUAGE = "en";
let REGION = "PK";
let LOCATION_TYPE = null;
const GOOGLE_API = "https://maps.googleapis.com/maps/api/geocode/json";

function handleUrl(url) {
  return fetch(url)
  .then(response => response.json())
  .catch((error) => {
    console.error("Error fetching data", error)
    return Promise.reject(new Error("Error fetching data"))
  });

  // if (json.status === "OK") {
  //   log(json);
  //   return json;
  // }
  // log(
  //   `${json.error_message}.\nServer returned status code ${json.status}`,
  //   true
  // );
  // return Promise.reject(
  //   new Error(
  //     `${json.error_message}.\nServer returned status code ${json.status}`
  //   )
  // );
}

export default {
  /**
   *
   *
   * @param {string} apiKey
   */
  setApiKey(apiKey) {
    API_KEY = apiKey;
  },

  /**
   *
   *
   * @param {string} language
   */
  setLanguage(language) {
    LANGUAGE = language;
  },

  /**
   *
   *
   * @param {string} region
   */
  setRegion(region) {
    REGION = region;
  },

  /**
   *
   *
   * @param {boolean} [flag=true]
   */
  enableDebug(flag = true) {
    DEBUG = flag;
  },

  /**
   *
   *
   * @param {string} locationType
   * Accepted values: ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE
   */
  setLocationType(locationType) {
    LOCATION_TYPE = locationType;
  },

  /**
   *
   *
   * @param {string} lat
   * @param {string} lng
   * @param {string} [apiKey]
   * @param {string} [language]
   * @param {string} [region]
   * @param {string} [locationType]
   * @returns {Promise}
   */
  fromLatLng(lat, lng, apiKey, language, region, locationType) {
    if (!lat || !lng) {
      console.warn("Provided coordinates are invalid");
      return Promise.reject(new Error("Provided coordinates are invalid"));
    }

    const latLng = `${lat},${lng}`;
    let url = `${GOOGLE_API}?latlng=${encodeURIComponent(latLng)}`;

    if (apiKey || API_KEY) {
      API_KEY = apiKey || API_KEY;
      url += `&key=${API_KEY}`;
    }

    if (language || LANGUAGE) {
      LANGUAGE = language || LANGUAGE;
      url += `&language=${LANGUAGE}`;
    }

    if (region || REGION) {
      REGION = region || REGION;
      url += `&region=${encodeURIComponent(REGION)}`;
    }

    if (locationType || LOCATION_TYPE) {
      LOCATION_TYPE = "APPROXIMATE" || REGION;
      url += `&location_type=${encodeURIComponent(LOCATION_TYPE)}`;
    }

    return handleUrl(url);
  },

  /**
   *
   *
   * @param {string} address
   * @param {string} [apiKey]
   * @param {string} [language]
   * @param {string} [region]
   * @returns {Promise}
   */
  fromAddress(address, apiKey, language, region) {
    if (!address) {
      console.warn("Provided address is invalid");
      return Promise.reject(new Error("Provided address is invalid"));
    }

    let url = `${GOOGLE_API}?address=${encodeURIComponent(address)}`;

    if (apiKey || API_KEY) {
      API_KEY = apiKey || API_KEY;
      url += `&key=${API_KEY}`;
    }

    if (language || LANGUAGE) {
      LANGUAGE = language || LANGUAGE;
      url += `&language=${LANGUAGE}`;
    }

    if (region || REGION) {
      REGION = region || REGION;
      url += `&region=${encodeURIComponent(REGION)}`;
    }

    return handleUrl(url);
  },
};
