export const getComponentOptionValueFromOption = (option) => ({
  id: option.component_option_value_id, 
  option_value_id: option.option_value_id,
  price: option.price,
  min_price: option.min_price,
  status: option.status,
  //temp variables
  name: option.name,
  option_name: option.name,
  description: option.description
})
