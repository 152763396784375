import React from "react";
import { I18nRails } from "../../shared/rails-i18n-js";
import withReview from "./hoc/withReview";
import * as rh from "./helpers/routes";
import withParent from "./hoc/withParent";
import { isLoggedIn } from "./helpers/utils";

const AppMenuReviews = ({
  Rails,
  reviews,
  review_title,
  review,
  createReview,
  setReviewContent,
}) => {
  return (
    <div className="modal hide fade" id="review">
      <div className="modal-content">
        <main className="pad-right">{review_title}</main>
        {reviews.map((review) => (
          <div className="reviews-list" key={review.id}>
            <div className="review-content">{review.content}</div>
            <div className="reviewer-name">
              {review.user.first_name} {review.user.last_name} at{" "}
              {I18nRails.l("date.formats.short", review.created_at)}
            </div>
          </div>
        ))}
        {isLoggedIn(Rails) && (
          <form
            className="personal-form"
            name="review"
            onSubmit={(e) => createReview(review)}
          >
            <div className="row">
              <div className="input-field col s6 p-data">
                <input
                  type="text"
                  value={review.content}
                  onChange={setReviewContent}
                />
                <label>{I18nRails.t("Web.write_a_review")}</label>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <button
                  type="submit"
                  className="btn btn-block btn-login stam-blue waves-effect waves-light"
                >
                  {I18nRails.t("Web.create")}
                </button>
              </div>
            </div>
          </form>
        )}
        <div className="back-button">
          <div className="col s12">
            <button>
              <Link to={rh.show_location({ location_id: locationId })}>
                Back to Location
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withParent(withReview(AppMenuReviews));
