import ReactResource from 'react-resource';

export default function FoodnerdResource(access_token, url, mappings = {}, customActions = {}) {
  const foodnerdResource = new ReactResource(url, mappings, customActions);
  foodnerdResource.interceptors.push({
    request: function(config) {
      if (!!access_token) config.options.headers['Authorization'] = `Bearer ${ access_token }`;
      config.options.headers['Time-Zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return config;
    }
  });
  return foodnerdResource;
}