import React from "react";
import { Link } from "react-router-dom";

import { I18nRails } from "../../shared/rails-i18n-js";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { resetBasketTotals } from "./Model/basket";
import { orderItemDisplayDescription, orderItemDisplayTitle } from "../models/order_item";


const StickyCart = ({
  cartService,
  basket,
  changeQuantity,
  removeBasketItem,

}) => {

  resetBasketTotals(basket, cartService);
  return (
    !!basket && (
      <div style={{ position: 'sticky', top: '75px' }}>
        <Grid container style={{ position: 'sticky', zIndex: '100', padding: 5 }} justify="center" alignContent="center">
          <Grid item xs={6}>
            <p className="subhead-font">
              <i className="material-icons center subHeadIcons first-color">restaurant</i>
              { I18nRails.t("Web.your_order_is_here") }
            </p>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            {basket.location.deliveries[0] && basket.location.deliveries[0].min_delivery_amount && (
              <p>
                { I18nRails.t("Web.min_order") } : Rs. { basket.location.deliveries[0].min_delivery_amount } /-
              </p>
            )}
          </Grid>
          <Grid container className="stickycart-container">
            {basket.items.map((item, index) => (
              (item.type == "OrderItem" || item.price > 0) && (
                <>
                  <Grid xs={6} item style={{ paddingRight: '0px', paddingLeft: '5px' }}>
                    <p className="zero-margin">{ orderItemDisplayTitle(item) }</p>
                  </Grid>
                  <Grid xs={3} item style={{ paddingRight: '0px', paddingLeft: '3px', marginTop: '15px', marginBottom: '15px' }}>
                    <div>
                      <i
                        className="material-icons"
                        style={{ fontSize: '20px', color: 'red', cursor: 'pointer', paddingTop: '1px', verticalAlign: 'sub' }}
                        onClick={(e) => changeQuantity(item, 'remove')}
                      >
                        remove
                      </i>
                      <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                        { item.weighted_number }
                      </span>
                      <i
                        className="material-icons"
                        style={{ fontSize: '19px', color: 'green', cursor: 'pointer', paddingTop: '1px', verticalAlign: 'sub' }}
                        onClick={(e) => changeQuantity(item, 'add')}
                      >
                        add
                      </i>
                    </div>
                  </Grid>
                  <Grid xs={2} item style={{ paddingRight: '0px', paddingLeft: '2px' }}>
                    <p className="zero-margin">
                      { item.price ? item.price : Number(item.menu_item.price) }
                    </p>
                  </Grid>
                  <Grid xs={1} item style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                    <div>
                      <i
                        className="material-icons"
                        onClick={(e) => removeBasketItem(item)}
                        style={{ fontSize: '16px', color: 'red', cursor: 'pointer', marginTop: '20px' }}
                      >
                        cancel
                      </i>
                    </div>
                  </Grid>
                  <Grid xs={9} item>
                    <sub>{ orderItemDisplayDescription(item) }</sub>
                  </Grid>
                </>
              )
            ))}
            {basket.totals.numberOfCartItems > 0 && (
              <>
                {basket.totals.gst > 0 && (
                  <>
                    <Grid xs={9} item style={{ paddingLeft: '3px' }}>
                      <p>
                        <i style={{fontSize:'20px'}} className="material-icons center subHeadIcons second-color">attach_money</i>
                        GST
                      </p>
                    </Grid>
                    <Grid xs={3} item>
                      <p>{basket.totals.gst}</p>
                    </Grid>
                  </>
                )}
                {basket.totals.discount > 0 && (
                  <>  
                    <Grid xs={9} item>
                      <p>
                        <i style={{fontSize:'20px'}} className="material-icons center subHeadIcons second-color">monetization_on</i>
                        {I18nRails.t("Web.total_discount")}
                      </p>
                    </Grid>
                    <Grid xs={3} item>
                      <p className="zero-margin">{basket.totals.discount}</p>
                    </Grid>
                  </>
                )}
                {basket.totals.delivery_charges > 0 && (
                  <>
                    <Grid xs={9} item>
                      <p>
                        <i style={{fontSize:'20px'}} className="material-icons center subHeadIcons second-color">motorcycle</i>
                        {I18nRails.t("Web.delivery_charges")}
                      </p>
                    </Grid>
                    <Grid xs={3} item>
                      <p className="zero-margin">
                        {I18nRails.numberToCurrency(
                          basket.totals.delivery_charges,
                          { unit: basket.location.currency_symbol }
                        )}
                      </p>
                    </Grid>
                  </>
                )}
                {basket.totals.final_price > 0 && (
                  <>
                    <Grid item xs={8} style={{ paddingLeft: '3px' }}>
                      <p>
                        <i style={{fontSize:'20px'}} className="material-icons center subHeadIcons second-color">monetization_on</i>
                        {I18nRails.t("Web.total_amount")}
                      </p>
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '7%' }}>
                      <p>
                        {I18nRails.numberToCurrency(
                          basket.totals.final_price,
                          { unit: basket.location.currency_symbol }
                        )}
                      </p>
                    </Grid>
                  </>
                )}
                <Divider />
              </>
            )}
          </Grid>
          {basket.totals.numberOfCartItems > 0 && (
            <Link
              to={'/locations/' + basket.location_id + '/cart'}
              className="checkout-btn .btn btn-second"
            >
              {I18nRails.t("Web.checkout")}
            </Link>
          )}
        </Grid>
      </div>
    )
  );
  
  // return pug`
  // if !!basket
  //   div(style={position:'sticky', top:'75px'})
  //     Grid(container  style={position:'sticky', zIndex:'100',padding:5} justify="center" alignContent="center" )
  //       Grid(item xs=6)
  //         p.subhead-font
  //           i.material-icons.center.subHeadIcons.first-color restaurant
  //           | ${ I18nRails.t("Web.your_order_is_here") }
  //       Grid(item xs=12)
  //         Divider()
  //         if basket.location.deliveries[0] && basket.location.deliveries[0].min_delivery_amount
  //           p ${ I18nRails.t("Web.min_order") } : Rs. ${ basket.location.deliveries[0].min_delivery_amount
  //   } /-
  //       Grid(container className="stickycart-container")
  //         each item, index in basket.items 
  //           if item.type == "OrderItem" || item.price > 0
  //             Grid(xs=6 item style={paddingRight:'0px', paddingLeft:'5px'})
  //               p.zero-margin
  //                 | ${ item.displayTitle() } 
  //             Grid(xs=3 item style={paddingRight:'0px', paddingLeft:'3px', marginTop:'15px', marginBottom:'15px'})
  //               div
  //                 i(style={fontSize:'20px', color:'red',cursor:'pointer', paddingTop:'1px', verticalAlign:'sub'} onClick = (e)=>changeQuantity(item, 'remove')).material-icons remove
  //                 span(style={paddingLeft:'5px', paddingRight:'5px'}) ${ item.weighted_number
  //   }
  //                 i(style={fontSize:'19px', color:'green',cursor:'pointer', paddingTop:'1px', verticalAlign:'sub'} onClick = (e)=>changeQuantity(item, 'add')).material-icons add
  //             Grid(xs=2 item style={paddingRight:'0px', paddingLeft:'2px'})
  //               p.zero-margin
  //               | ${ item.price ? item.price : Number(item.menu_item.price) } 
  //             Grid(xs=1 item style={paddingRight:'0px', paddingLeft:'0px'})
  //               div
  //               i(onClick = (e)=>props.removeBasketItem(item), style={fontSize:'16px', color:'red',cursor:'pointer', marginTop:'20px'}).material-icons cancel
  //             Grid(xs=9 item)
  //               sub ${ item.displayDescription() }
  //         if basket.totals.numberOfCartItems > 0 
  //           if basket.totals.gst > 0
  //             Grid(xs=9 item style={paddingLeft:'3px'})
  //               p
  //                 i(style={fontSize:'20px'}).material-icons.center.subHeadIcons.second-color attach_money
  //                 | GST
  //             Grid(xs=3 item)
  //               p ${ basket.totals.gst }
  //           if basket.totals.discount > 0
  //             Grid(xs=9 item)
  //               p
  //                 i(style={fontSize:'20px'}).material-icons.center.subHeadIcons.second-color monetization_on
  //                 | ${ I18nRails.t("Web.total_discount") }
  //             Grid(xs=3 item)
  //               p.zero-margin ${ basket.totals.discount }
  //           if basket.totals.delivery_charges > 0
  //             Grid(xs=9 item)
  //               p
  //                 i(style={fontSize:'20px'}).material-icons.center.subHeadIcons.second-color motorcycle
  //                 | ${ I18nRails.t("Web.delivery_charges") }
  //             Grid(xs=3 item)
  //               p.zero-margin ${ I18nRails.numberToCurrency(
  //     basket.totals.delivery_charges,
  //     { unit: basket.location.currency_symbol }
  //   ) }
  //           if basket.totals.final_price > 0
  //             Grid(item xs=8 style={paddingLeft:'3px'})
  //               p
  //                 i(style={fontSize:'20px'}).material-icons.center.subHeadIcons.second-color monetization_on
  //                 | ${ I18nRails.t("Web.total_amount") }
  //             Grid(item xs=4 style={paddingLeft:'7%'})
  //               p ${ I18nRails.numberToCurrency(
  //     basket.totals.final_price,
  //     { unit: basket.location.currency_symbol }
  //   ) }
  //           Divider
  //       if basket.totals.numberOfCartItems > 0
  //         Link(to='/locations/' + basket.location_id + '/cart', className='checkout-btn .btn btn-second') ${ I18nRails.t(
  //     "Web.checkout"
  //   ) }
  // `;
}

export default StickyCart;
