import Cookie from 'js-cookie';
let authenticity_token = Cookie.get('csrftoken');


let param_meta = document.createElement("meta");
param_meta.name = 'csrf-param';
param_meta.content = 'authenticity_token';

let token_meta = document.createElement("meta");
token_meta.name = 'csrf-token';
token_meta.content = authenticity_token;

document.head.appendChild(param_meta);
document.head.appendChild(token_meta);
