import React from 'react';

import { I18nRails } from '../../shared/rails-i18n-js';

const TermsAndConditions = () => {
  return (
    <>
      <h4>{I18nRails.t('display.terms.title')}</h4>
      <h5>{I18nRails.t('display.terms.terms_of_use.title')}</h5>
      <p>{I18nRails.t('display.terms.terms_of_use.content')}</p>
      <h5>{I18nRails.t('display.terms.description.title')}</h5>
      <div className="divider"></div>
      <p>{I18nRails.t('display.terms.description.content')}</p>
      <h5>{I18nRails.t('display.terms.guidelines.title')}</h5>
      <div className="divider"></div>
      <p>
        {I18nRails.t('display.terms.guidelines.content')}
        {I18nRails.t('display.terms.guidelines.warning')}
      </p>
      <p>{I18nRails.t('display.terms.guidelines.content_second')}</p>
      <h5>{I18nRails.t('display.terms.for_payments.title')}</h5>
      <div className="divider"></div>
      <p>
        {I18nRails.t('display.terms.for_payments.content')}
        {I18nRails.t('display.terms.for_payments.content_second')}
      </p>
      <h5>{I18nRails.t('display.terms.returns_withdrawls.title')}</h5>
      <div className="divider"></div>
      <p>{I18nRails.t('display.terms.returns_withdrawls.content')}</p>
      <h5>{I18nRails.t('display.terms.returns_withdrawls.gift_cards.title')}</h5>
      <div className="divider"></div>
      <p>{I18nRails.t('display.terms.returns_withdrawls.gift_cards.content')}</p>
      <h5>{I18nRails.t('display.terms.security_online_payments.title')}</h5>
      <div className="divider"></div>
      <p>{I18nRails.t('display.terms.security_online_payments.content')}</p>
      <h5>{I18nRails.t('display.terms.purchases_gift_cards.title')}</h5>
      <div className="divider"></div>
      <p>{I18nRails.t('display.terms.purchases_gift_cards.content')}</p>
      <h5>{I18nRails.t('display.terms.purchases_gift_cards.purchase_delivery.title')}</h5>
      <div className="divider"></div>
      <p>{I18nRails.t('display.terms.purchases_gift_cards.purchase_delivery.content')}</p>
      <h5>{I18nRails.t('display.terms.restrictions.title')}</h5>
      <div className="divider"></div>
      <p>{I18nRails.t('display.terms.restrictions.content')}</p>
      <h5>{I18nRails.t('display.terms.user_profile.title')}</h5>
      <div className="divider"></div>
      <p>{I18nRails.t('display.terms.user_profile.content')}</p>
      <h5>{I18nRails.t('display.terms.limitation_liability.title')}</h5>
      <div className="divider"></div>
      <p>{I18nRails.t('display.terms.limitation_liability.content')}</p>
      <h5>{I18nRails.t('display.terms.handling_data.title')}</h5>
      <div className="divider"></div>
      <p>{I18nRails.t('display.terms.handling_data.content')}</p>
      <p>{I18nRails.t('display.terms.footer')}</p>
    </>
  );
  
  // return pug`
  //   h4= I18nRails.t('display.terms.title')
  //   h5= I18nRails.t('display.terms.terms_of_use.title')
  //   p= I18nRails.t('display.terms.terms_of_use.content')
  //   h5= I18nRails.t('display.terms.description.title')
  //   .divider
  //   p= I18nRails.t('display.terms.description.content')
  //   h5= I18nRails.t('display.terms.guidelines.title')
  //   .divider
  //   p
  //     = I18nRails.t('display.terms.guidelines.content')
  //     = I18nRails.t('display.terms.guidelines.warning')
  //   p
  //     = I18nRails.t('display.terms.guidelines.content_second')
  //   h5= I18nRails.t('display.terms.for_payments.title')
  //   .divider
  //   p
  //     = I18nRails.t('display.terms.for_payments.content')
  //     = I18nRails.t('display.terms.for_payments.content_second')
  //   h5= I18nRails.t('display.terms.returns_withdrawls.title')
  //   .divider
  //   p
  //     = I18nRails.t('display.terms.returns_withdrawls.content')
  //   h5= I18nRails.t('display.terms.returns_withdrawls.gift_cards.title')
  //   .divider
  //   p= I18nRails.t('display.terms.returns_withdrawls.gift_cards.content')
  //   h5= I18nRails.t('display.terms.security_online_payments.title')
  //   .divider
  //   p
  //     = I18nRails.t('display.terms.security_online_payments.content')
  //   h5= I18nRails.t('display.terms.purchases_gift_cards.title')
  //   .divider
  //   p
  //     = I18nRails.t('display.terms.purchases_gift_cards.content')
  //   h5= I18nRails.t('display.terms.purchases_gift_cards.purchase_delivery.title')
  //   .divider
  //   p
  //     = I18nRails.t('display.terms.purchases_gift_cards.purchase_delivery.content')
  //   h5= I18nRails.t('display.terms.restrictions.title')
  //   .divider
  //   p= I18nRails.t('display.terms.restrictions.content')
  //   h5= I18nRails.t('display.terms.user_profile.title')
  //   .divider
  //   p= I18nRails.t('display.terms.user_profile.content')
  //   h5= I18nRails.t('display.terms.limitation_liability.title')
  //   .divider
  //   p= I18nRails.t('display.terms.limitation_liability.content')
  //   h5= I18nRails.t('display.terms.handling_data.title')
  //   .divider
  //   p= I18nRails.t('display.terms.handling_data.content')
  //   p= I18nRails.t('display.terms.footer')
  // `;
};

export default TermsAndConditions;


