import React, { Component, useState, useRef, useEffect } from 'react';


import AppLayout from './AppLayout';
import InfiniteOrders from './InfiniteOrders';

import { I18nRails } from '../../shared/rails-i18n-js';

import * as rh from './helpers/routes';

import _loginOrSignup from './templates/_loginOrSignup';
import withOrders from './hoc/withOrders';
import withParent from './hoc/withParent';
import { useLocation } from 'react-router';

const AppOrders = ({
  Rails,
  listenForUpdates,
  locationId,
  loadingOrders,
  user,
  hasMore,
  infiniteOrders,
  loadMoreOrders,
  ...other
}) => {
  const domLocation = useLocation();
  useEffect(() => {
      listenForUpdates(locationId);
  }, [locationId]);

  return (
    <AppLayout user={user} Rails={Rails} {...other}>
      {user ? (
        <InfiniteOrders
          items={infiniteOrders.items}
          loadMoreOrders={loadMoreOrders}
          loading={loadingOrders}
          hasMore={hasMore}
          Rails={Rails}
          {...other}
        />
      ) : (
        _loginOrSignup('Login to your existing account or Sign Up.', domLocation)
      )}
    </AppLayout>
  );

  //   return pug`
  //     AppLayout(user=this.state.user, ...this.props)
  //       if this.state.user
  //         InfiniteOrders(items=this.infiniteOrders.items, loadMoreOrders=this.loadMoreOrders.bind(this), loading=this.state.loadingOrders, hasMore=this.state.hasMore, ...this.props)    
  //       else
  //         = _loginOrSignup('Login to your existing account or Sign Up.', this.props.location)
  // `;
};


export default withParent(withOrders(AppOrders));