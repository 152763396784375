import React from "react";
// import AppCartConfirm from './components/AppCartConfirm'
// import AppCheckoutAddresses from './components/AppCheckoutAddresses'
import AppReload from "./components/AppReload";
import AppFavorites from "./components/AppFavorites";
import AppLocationAbout from "./components/AppLocationAbout";
import AppOrders from "./components/AppOrders";
import AppResetPassword from "./components/AppResetPassword";
import AppUpdatePassword from "./components/AppUpdatePassword";
import AppUpdates from "./components/AppUpdates";
import AppUserAddresses from "./components/AppUserAddresses";

// import AppCheckout from "./components/AppLocationCart";
import AppCart from "./components/AppCart";
import AppLogin from "./components/AppLogin";
import AppMain from "./components/AppMain";
import AppRegister from "./components/AppRegister";
import AppLocations from "./components/AppLocations";
import AppLocation from "./components/AppLocation";
import AppLocationCart from "./components/AppLocationCart";
import AppLocationBlogs from "./components/AppLocationBlogs";
import AppOrder from "./components/AppOrder";
import AppLocationOrders from "./components/AppLocationOrders";
import AppLocationReviews from "./components/AppLocationReviews";
import AppMenuItemReviews from "./components/AppMenuItemReviews";
import AppMenuReviews from "./components/AppMenuReviews";
import AppProfile from "./components/AppProfile";

export const locationRoutesOnly = (props) => [
  {
    exact: true,
    path: '/',
    element: <AppLocation {...props} />,
  },
  {
    exact: true,
    path: '/editor/blogs/new',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/editor/pages/new',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/about',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/orders',
    element: <AppLocationOrders {...props} />,
  },
  {
    exact: true,
    path: '/blogs',
    element: <AppLocationBlogs {...props} />,
  },
  {
    exact: true,
    path: '/pages/:page_id',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/reviews',
    element: <AppLocationReviews {...props} />,
  },
  {
    exact: true,
    path: '/menu_reviews/:id',
    element: <AppMenuReviews {...props} />,
  },
  {
    exact: true,
    path: '/menus/:menu_id/menu_item_reviews/:id',
    element: <AppMenuItemReviews {...props} />,
  }
];

export const siteRoutesOnly = (props) => [
  {
    exact: true,
    path: '/locations',
    element: <AppLocations {...props} />,
    data: {
      bodyClass: "home-bg",
    },
  },
  {
    exact: true,
    path: '/',
    element: <AppMain {...props} />,
  },
  {
    exact: true,
    path: '/blogs',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/favorites',
    element: <AppFavorites {...props} />,
  },
  {
    exact: true,
    path: '/testimonials',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/contact',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/pricing',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/careers',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/how-it-works',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/editor/locations/:location_id/blogs/new',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/editor/locations/:location_id/pages/new',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/locations/:location_id/about',
    element: <AppLocationAbout {...props} />,
  },
  {
    exact: true,
    path: '/locations/:location_id/orders',
    element: <AppLocationOrders {...props} />,
  },
  {
    exact: true,
    path: '/locations/:location_id/blogs',
    element: <AppLocationBlogs {...props} />,
  },
  {
    exact: true,
    path: '/locations/:location_id/pages/:page_id',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/locations/:location_id/reviews',
    element: <AppLocationReviews {...props} />,
  },
  {
    exact: true,
    path: '/locations/:location_id/menu_reviews/:id',
    element: <AppMenuReviews {...props} />,
  },
  {
    exact: true,
    path: '/locations/:location_id/menus/:menu_id/menu_item_reviews/:id',
    element: <AppMenuItemReviews {...props} />,
  }
];

const commonRoutes = (props) => [
  {
    path: '/manager/login',
    element: <AppLogin {...props} />,
  },
  {
    exact: true,
    path: '/manager/pos',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/admin',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/manager/:route',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/manager/:routes/:route',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/business',
    element: <AppReload {...props} />,
  },
  {
    exact: true,
    path: '/orders',
    element: <AppOrders {...props} />,
    data: {
      bodyClass: "frontpage-bg",
      meta: {
        title: "My Orders",
      },
    },
  },
  {
    exact: true,
    path: '/updates',
    element: <AppUpdates {...props} />,
    data: {
      bodyClass: "frontpage-bg",
      meta: {
        title: "Updates",
      },
    },
  },
  {
    exact: true,
    path: '/cart',
    element: <AppCart {...props} />,
    data: {
      bodyClass: "frontpage-bg",
      meta: {
        title: "Cart",
      },
    },
  },
  {
    exact: true,
    path: '/locations/:location_id/cart',
    element: <AppLocationCart {...props} />,
  },
  {
    exact: true,
    path: '/orders/:id',
    element: <AppOrder {...props} />,
  },
  {
    exact: true,
    path: '/locations/:location_id',
    element: <AppLocation {...props} />,
  },
  {
    exact: true,
    path: '/apps/client/locations',
    element: <AppLocations {...props} />,
  },
  {
    exact: true,
    path: '/apps/client',
    element: <AppLocations {...props} />,
  },
  {
    exact: true,
    path: '/users/me',
    element: <AppProfile {...props} />,
  },
  {
    exact: true,
    path: '/addresses',
    element: <AppUserAddresses {...props} />,
  },
  {
    exact: true,
    path: '/reorder/:order_id',
    element: <AppLocationCart {...props} />,
  },
  // {
  //   exact: true
  //   path: (parentRoute) -> "#{parentRoute}/confirm"
  //   element: <AppCartConfirm
  //   data:
  //     bodyClass: 'frontpage-bg'
  //     meta:
  //       'title': 'Confirm Order'
  // }
  // {
  //   exact: true
  //   path: (parentRoute) -> "#{parentRoute}/locations/:location_id/confirm"
  //   element: <AppCartConfirm
  //   data:
  //     bodyClass: 'frontpage-bg'
  //     meta:
  //       'title': 'Confirm Order'
  // }
  //  {
  //    exact: true
  //    path: (parentRoute) -> "#{parentRoute}/access_token=:response"
  //    controller: [
  //      '$state'
  //      'AccessTokenFactory'
  //      ($state, AccessTokenFactory) ->
  //        token = $state.params.response.match(/^(.*?)&/)[1]
  //        AccessTokenFactory.set(token)
  //        $state.go 'index'
  //    ]
  //    data:
  //      bodyClass: 'frontpage-bg'
  //      meta:
  //        'title': 'Access Token'
  //  }
  {
    exact: true,
    path: '/login',
    element: <AppLogin {...props} />,
  },
  {
    exact: true,
    path: '/locations/:location_id/login/:state',
    //element: angular2ReactComponent('appLogin', AppLogin)
    element: <AppLogin {...props} />,
  },
  {
    exact: true,
    path: '/join',
    element: <AppRegister {...props} />,
  },
  {
    exact: true,
    path: '/forgot_password',
    element: <AppResetPassword {...props} />,
  },
  {
    exact: true,
    path: '/reset/:token',
    element: <AppUpdatePassword {...props} />,
  }
];

export const locationRoutes = (props) => [...commonRoutes(props), ...locationRoutesOnly(props)];
export const siteRoutes = (props) => [...commonRoutes(props), ...siteRoutesOnly(props)];
export default (Rails, props) => {
  if (!!Rails.domain_place_id && Rails.domain_place_type === "Location") {
    return [...commonRoutes(props), ...locationRoutesOnly(props)];
  } else {
    return [...commonRoutes(props), ...siteRoutesOnly(props)];
  }
};

//www.islamabadchicken/products - location domain routes
//www.howmuch.pk/islamabad-chicken/products - main domain routes
