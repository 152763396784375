import React, { Component } from 'react';
import { I18nRails } from '../../shared/rails-i18n-js';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import * as rh from './helpers/routes';

import { withNavigate } from "../../react-pos/components/withNavigate";

import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router';

const MenuItemCard = ({
  menu_item  
}) => {

  const navigate = useNavigate()
  return (
    <Card style={{height:'245px'}} onClick={(e) => navigate(rh.show_location({location_id: menu_item[0].location_slug}))}>
      <CardMedia>
        <img style={{ height: '170px', width: '300px', objectFit: 'cover' }} src={menu_item[0].obj.url_image1}/>
      </CardMedia>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <h4 style={{marginTop:3}}>
              {menu_item[0].obj.title}
            </h4>
          </Grid>
          <Grid item xs={6}>
            <div className='dish-price'>
              <a>{ I18nRails.numberToCurrency(menu_item[0].obj.price, {unit: 'Rs'}) }</a>
            </div>
          </Grid>
          <Grid item xs={9}>
            <div>
              { menu_item[0].obj.description }
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
  // return pug`
  //   Card(style={height:'245px'} onClick=(e) => this.navigate(rh.show_location({location_id: menu_item[0].location_slug})) )
  //     CardMedia
  //       img(style={ height: '170px', width: '300px', objectFit: 'cover' } src=menu_item[0].obj.url_image1)
  //     CardContent(style={paddingTop:5})
  //       Grid(container)
  //         Grid(item xs=6)
  //           h4(style={marginTop:3})= menu_item[0].obj.title
  //         Grid(item xs=6)
  //           .dish-price
  //             a= I18nRails.numberToCurrency(menu_item[0].obj.price, {unit: 'Rs'})
  //         Grid(item xs=9)
  //           div= menu_item[0].obj.description

  // `;
};

export default MenuItemCard;
