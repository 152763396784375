import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";

import AppLayout from './AppLayout';
import AuthFactory from '../services/AuthFactory';
import sweetalert2 from 'sweetalert2';
import AccessTokenFactory from '../services/AccessTokenFactory';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import loginImg from '../../images/Login-small.jpg';
import { I18nRails } from '../../shared/rails-i18n-js';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import withParent from './hoc/withParent';

const AppRegister = ({
  Rails,
  notifyNative,
  user,
  ...other
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [offers, setOffers] = useState([]);

  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    password_confirmation: ''
  });

  const [errors0, setErrors] = useState();

  useEffect(() => {
    notifyNative('onSignup');
  }, [])

  const processServerValidationsFromResponse = (server_validations) => {
    const errors = [];

    for (let key in server_validations) {

      const list = server_validations[key];
      const error = {};

      for (let item of list) {
        if (key === 'phone_normalized') { key = 'phone'; }

        // showing only one error at a each time.
        error.key = key;
        error[key] = item;
      }

      errors.push(error);
    }

    return errors;
  }

  const validateHandler = (values) => {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = [I18nRails.t('client.profile.login.first_name_required')];
    } else if (!/[A-Z0-9]+/i.test(values.first_name)) {
      errors.first_name = [I18nRails.t('client.profile.login.invalid_name')];
    }

    if (!values.last_name) {
      errors.last_name = [I18nRails.t('client.profile.login.last_name_required')];
    } else if (!/[A-Z0-9]+/i.test(values.last_name)) {
      errors.last_name = [I18nRails.t('client.profile.login.invalid_name')];
    }

    if (!values.phone) {
      errors.phone = [I18nRails.t('client.profile.login.phone_required')];
    } else if (!/[0-9+]+/i.test(values.phone)) {
      errors.phone = [I18nRails.t('client.profile.login.invalid_phone')];
    }

    if (!values.email) {
      errors.email = [I18nRails.t('client.profile.login.email_required')];
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = [I18nRails.t('client.profile.login.invalid_email_address')];
    }

    if (!values.password) {
      errors.password = [I18nRails.t('client.profile.login.password_required')];
    } else if (values.password.length < 6) {
      errors.password = [I18nRails.t('client.profile.login.password_characters')];
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = [I18nRails.t('client.profile.login.please_confirmPassword')];
    } else if (values.password_confirmation) {
      if (values.password_confirmation !== values.password) {
        errors.password_confirmation = [I18nRails.t('client.profile.login.no_match')];
      }
    }

    return errors;
  };

  const onSubmit = (values, options) => {
    let promise;
    setErrors([]);
    const authFactory = new AuthFactory(Rails);

    return promise = authFactory.signup(values).then(
      response => {
        if (!!response) {
          if (!!response.errors || !!response.data.errors) {
            options.setSubmitting(false);

            const validations = response.errors || response.data.errors;

            setErrors(processServerValidationsFromResponse(validations));

            try {
              window.scrollTo(0, 0);
            } catch (e) { }

          } else {
            const accessTokenFactory = new AccessTokenFactory(Rails);

            AccessTokenFactory.set(response.data.access_token);
            //            react only support path and state since we are not setting any state any place.
            //            In future we can enable this code and start using path combined with state object.
            if (params && params.path) {
              const state = params.state || {};
              navigate(params.path, state);
            } else {
              navigate('/locations');
            }
          }

          return options.validateForm(values);
        } else {
          return options.setSubmitting(false);
        }
      }
      , error => {
        options.setSubmitting(false);
        return sweetalert2.fire(error.response.data.error);
      });
  };

  const children = ({ values, errors, touched, isSubmitting, isValidating }) => {

    return (
      <Form method="post" className="form-signin login-form">
        {errors0 && errors0.map((item, index) => (
          <div key={item.key} className="server-validations" name="signup-form">{'- ' + item.key + ' ' + item[item.key]}</div>
        ))}
        <Grid container spacing={4} justify="center" alignItems="center">
          <Grid item xs={5}>
            <Field name="first_name" placeholder="First Name *" required type="text" autoComplete="first-name" className="browser-default first_name" component="input" />
            <ErrorMessage name="first_name" component="validation-message" />
          </Grid>
          <Grid item xs={5}>
            <Field name="last_name" placeholder="Last Name *" required type="text" autoComplete="last-name" className="browser-default last_name" component="input" />
            <ErrorMessage name="last_name" component="validation-message" />
          </Grid>
          <Grid item xs={5}>
            <Field name="phone" placeholder="Phone (+92XXXXXXXXXX) *" required type="tel" autoComplete="phone" className="browser-default phone" component="input" />
            <ErrorMessage name="phone" component="validation-message" />
          </Grid>
          <Grid item xs={5}>
            <Field name="email" placeholder="Email *" required type="email" autoComplete="email" className="browser-default email" component="input" />
            <ErrorMessage name="email" component="validation-message" />
          </Grid>
          <Grid item xs={5}>
            <Field name="password" type="password" required minLength={8} placeholder="Password *" autoComplete="new-password" className="browser-default" component="input" />
            <ErrorMessage name="password" component="validation-message" />
          </Grid>
          <Grid item xs={5}>
            <Field name="password_confirmation" type="password" required minLength={8} placeholder="Confirm Password *" autoComplete="confirm-password" className="browser-default password_confirmation" component="input" />
            <ErrorMessage name="password_confirmation" component="validation-message" />
          </Grid>
          <Grid xs={10} item>
            <Button className="btn btn-block bt-login btn-second waves-effect waves-light login_btn" style={{width: '100%'}} disabled={Object.keys(errors).length || isSubmitting || isValidating} onSubmit={onSubmit} type="submit">
              {I18nRails.t('client.profile.login.register')}
            </Button>
          </Grid>
        </Grid>
      </Form>
    );    
  };

  return (
    <AppLayout user={user} Rails={Rails} {...other}>
      <Grid item xs={12} style={{ height: '95vh', marginTop: '5%', backgroundImage: 'url(' + loginImg + ')' }}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={10} sm={10} md={4} lg={4}>
            <h3 style={{marginTop:'2%'}} className="center white-text">{I18nRails.t('client.profile.login.join_foodnerd')}</h3>
            <Card elevation={7} style={{marginBottom: '1%', height: 'auto', padding: '25px'}}>
              <Formik
                initialValues={values}
                validate={validateHandler}
                onSubmit={onSubmit}
                name="signup-form"
                className="form-signin mg-btm login-form"
              >
                {children}
              </Formik>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Link className="btnLogin btn-first" href="/login">
              {I18nRails.t('client.profile.login.already_a_user')}
              <span className="largeText">{I18nRails.t('client.profile.login.normal')}</span>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default withParent(AppRegister);