import FoodnerdResource from './FoodnerdResource';
export default class UserAddressResource {
  static get(Rails) {
    const base = `${ Rails.host }/users/me`;
    const mappings = { id: ':id' };
    const UserAddress = new FoodnerdResource(Rails.access_token, `${ base }/addresses.json`, mappings, {
      update: {
        url: `${ base }/addresses.json`,
        method: 'PUT'
      },
      save: {
        url: `${ base }/addresses.json`,
        method: 'POST',
        transformResponse(data, headers) {
          if (!!data.errors) { data.address.errors = data.errors; }
          return data.address;
        }
      },

      update_coordinates: {
        method: 'PUT',
        url: "/users/{:user_id}/addresses/{:address_id}/update_coordinates.json",
        params: { user_id: ':user_id', address_id: ':address_id' }
      }
    });

    return UserAddress;
  }
}
