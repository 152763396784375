export default class onTimesParser {
  /*
   * @value ontimes is an array of ranges where the subject is "on"
   */
  static create(onTimes) {
    return onTimesParser.setOnTimes(onTimes);
  }


  /*
    * Parse the onTimes provided so they're easier to work with, and 
    * finaly save them in the object as onRanges
  */
  static setOnTimes(onTimes) {
    OnTimesParser.onRanges = [];
    return onTimes.map((range, key) =>
      OnTimesParser.onRanges.push({ 
        start : new Date(Date.parse(range[0])),
        end   : new Date(Date.parse(range[1]))
      }));
  }

  /*
    * Finds and returns the next off (range end) time based on some Date
  */
  static nextOffTime(d) {
    let targetOffTime = null;
    for (let range of OnTimesParser.onRanges) {
      if ((range.end > d) && ((range.end < targetOffTime) || !targetOffTime)) {
        targetOffTime = range.end;
      }
    }

    return targetOffTime;
  }

  /*
    * Finds and returns the next On (range start) time based on some Date
  */
  static nextOnTime(d) {
    let targetOnTime = null;
    for (let range of OnTimesParser.onRanges) {
      if ((range.start > d) && ((range.start < targetOnTime) || !targetOnTime)) {
        targetOnTime = range.start;
      }
    }

    return targetOnTime;
  }

  /*
    * Finds and return the previous closest On time based on some Date
  */
  static prevOnTime(d) {
    let targetOnTime = null;
    for (let range of OnTimesParser.onRanges) {
      if ((range.start < d) && ((range.start > targetOnTime) || !targetOnTime)) {
        targetOnTime = range.start;
      }
    }

    return targetOnTime;
  }

  /*
    * Finds and returns the closest (future) on range based on some Date
    * NOTE: The range can contain the provided Date
  */
  static closestOnRange(d) {
    const nextEvent = OnTimesParser.nextEvent(d);

    // The upcoming range is the one we want
    if (nextEvent.type === 'on') {
      return { start: nextEvent.time, end: OnTimesParser.nextOffTime(d) };

    // We want the range we are currently in
    } else if (nextEvent.type === 'off') {
      return { start: OnTimesParser.prevOnTime(d), end: nextEvent.time };
    }

    return null;
  }

  /*
    * Finds and returns the next time that the subject either goes on or off
    * together with the event type
  */
  static nextEvent(d) {
    const nextOff = OnTimesParser.nextOffTime(d);
    const nextOn  = OnTimesParser.nextOnTime(d);

    if ((nextOff < nextOn) || (!nextOn && nextOff)) {
      return { time: nextOff, type: 'off' };
    }

    if (nextOn) {
      return { time: nextOn, type: 'on' };
    }

    return { time: null, type: null };
  }

  /*
    * Is the subject on at the provided Date?
    * If the next thing that happens is that it goes off, then 
    * the subject is by common sense on, since it can't go on whilst being on
  */
  static isOnAt(d) {
    const nextOff = OnTimesParser.nextOffTime(d);
    const nextOn  = OnTimesParser.nextOnTime(d);

    if (!nextOff) { return false; }

    return nextOff < nextOn;
  }

  /*
    * Is the subject on Now?
  */
  static isOnNow() {
    return OnTimesParser.isOnAt(new Date());
  }

  /*
    * Is the subject on at the provided Date?
  */
  static isOffAt(d) {
    return !OnTimesParser.isOnAt(d);
  }

  /*
    * Is the subject on Now?
  */
  static isOffNow() {
    return !OnTimesParser.isOnNow();
  }
};
