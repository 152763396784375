export const resetBasketTotals = (basket, cartService) => {
  if (!basket || !basket.location_id) {
    return;
  }
  if (!basket.totals) {
    basket.totals = {};
  }
  const basket_totals = basket.totals;

  if (!basket_totals.voucher_discount) {
    basket_totals.voucher_discount = 0;
  }

  basket_totals.total = cartService.getTotal(basket.location_id);
  basket_totals.numberOfCartItems = cartService.numberOfItems(
    basket.location_id
  );
  basket_totals.discount =
    calculateDiscount(basket_totals.total, basket) +
    basket_totals.voucher_discount;
  basket_totals.totalss = basket_totals.total - basket_totals.discount;

  if (!!basket.location) {
    basket_totals.gst = calculateGst(basket_totals.totalss, basket.location);
  }

  basket_totals.delivery_total = basket_totals.delivery_charges || 0;

  if (!!basket.places_payment_method) {
    basket_totals.payment_fee_total = calculatePaymentCharges(
      basket,
      basket.places_payment_method
    );
  }
  basket_totals.payment_fee_total ||= 0;
  basket_totals.final_price =
    basket_totals.total -
    basket_totals.discount +
    basket_totals.gst +
    basket_totals.payment_fee_total +
    basket_totals.delivery_total;
};

export const calculatePaymentCharges = (basket, places_payment_method) => {
  let fee = 0;
  if (places_payment_method.tx_percentage_fee) {
    let payable_amount = basket.totals.total + basket.totals.delivery_total;
    fee += payable_amount * parseFloat(places_payment_method.tx_percentage_fee);
  }
  if (places_payment_method.tx_fee_amount) {
    fee += parseFloat(places_payment_method.tx_fee_amount);
  }
  return fee;
};

export const calculateDiscount = (total, basket) => {
  const order_items = basket.items;
  let discount = 0;

  discount = calculateMenuItemOfferDiscount(total, order_items);
  if (discount > 0) {
    return discount;
  }

  discount = calculateMenuOfferDiscount(total, order_items);
  if (discount > 0) {
    return discount;
  }

  if (!!basket.location_id && !!basket.location) {
    discount = calculateLocationOfferDiscount(total, basket.location);
  }
  if (discount > 0) {
    return discount;
  }

  return discount;
};

export const calculateMenuItemOfferDiscount = (total, order_items) => {
  let discount = 0;
  for (let order_item of order_items) {
    if (!!order_item.menu_item) {
      if (!!order_item.menu_item.offers) {
        if (order_item.menu_item.offers.length > 0) {
          var discount_off;
          const item_price = order_item.weighted_number * order_item.unit_price;
          const offer = order_item.menu_item.offers[0];
          if (
            !offer.minimum_order_amount ||
            offer.minimum_order_amount <= total
          ) {
            if (!!offer.flat_discount) {
              discount_off = offer.flat_discount * order_item.weighted_number;
            } else if (!!offer.percentage_discount) {
              discount_off = Math.round(
                (offer.percentage_discount / 100) * item_price
              );
              const { max_discount } = offer;
              if (!!max_discount && max_discount < discount_off) {
                discount_off = max_discount;
              }
            }
          }
          discount = discount + discount_off;
        }
      }
    } else {
      console.trace("Menu not fetched. NEEDS FIXING");
      throw Error("Menu not fetched. NEEDS FIXING");
    }
  }

  return discount;
};

export const calculateGst = (total, location) => {
  if (!!location.gst && location.gst > 0) {
    return Math.round((location.gst / 100) * total);
  }
  return 0;
};

export const calculateLocationOfferDiscount = (total, location) => {
  let discount = 0;
  if (!location.offers) {
    return discount;
  }
  if (location.offers.length < 1) {
    return discount;
  }

  for (let offer of location.offers) {
    if (
      //offer only applies to web channel or if no channel is specified
      (!offer.channels ||
        offer.channels.length < 1 ||
        offer.channels.includes("web")) &&
      (!offer.minimum_order_amount || offer.minimum_order_amount <= total)
    ) {
      if (!!offer.flat_discount) {
        if (total >= offer.minimum_order_amount) {
          return (discount = offer.flat_discount);
        }
      } else if (!!offer.percentage_discount) {
        discount = Math.round((offer.percentage_discount / 100) * total);
        const { max_discount } = offer;
        if (!!max_discount && max_discount <= discount) {
          discount = max_discount;
        }
      }
    }
  }
  return discount;
};

export const calculateMenuOfferDiscount = (total, order_items) => {
  let discount = 0;
  for (let order_item of order_items) {
    if (!!order_item.menu) {
      if (!!order_item.menu.offers) {
        if (order_item.menu.offers.length > 0) {
          var discount_off;
          const item_price = order_item.weighted_number * order_item.unit_price;
          const offer = order_item.menu.offers[0];
          if (
            !offer.minimum_order_amount ||
            offer.minimum_order_amount <= total
          ) {
            if (!!offer.flat_discount) {
              discount_off = offer.flat_discount * order_item.weighted_number;
            } else if (!!offer.percentage_discount) {
              discount_off = Math.round(
                (offer.percentage_discount / 100) * item_price
              );
              const { max_discount } = offer;
              if (!!max_discount && max_discount < discount_off) {
                discount_off = max_discount;
              }
            }
          }
          discount = discount + discount_off;
        }
      } else {
        console.trace("Menu not fetched. NEEDS FIXING");
        throw Error("Menu not fetched. NEEDS FIXING");
      }
    }
  }
  return discount;
};
