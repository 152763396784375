import React from 'react';
import { Link } from "react-router-dom";
import * as rh from '../helpers/routes';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Hidden from "@mui/material/Hidden";
import { I18nRails } from '../../../shared/rails-i18n-js';
import { loadImage } from '../helpers/utils';

const AppFooter = ({
  Rails,
  fndLocation,
  basket,
  numberOfCartItems,
  countryConfig
}) => {

  return (
    <>
      <Hidden mdUp={true}>
        {!basket || !fndLocation ? (
          <div className="bottom-nav" id="bottombar">
            <Link to="/locations" className="bottom-nav__action">
              <i className="material-icons">restaurant_menu</i>
              <span className="bottom-nav__label">{I18nRails.t("footer.locations")}</span>
            </Link>
            <Link to="/favorites" className="bottom-nav__action">
              <i className="material-icons">favorite</i>
              <span className="bottom-nav__label">Favorites</span>
            </Link>
            <Link to="/orders" className="bottom-nav__action">
              <i className="material-icons">list</i>
              <span className="bottom-nav__label">Recent Orders</span>
            </Link>
            <Link to="/cart" className="bottom-nav__action">
              <i className={`fas fa-shopping-cart ${numberOfCartItems > 0 ? 'badge1' : ''}`} data-badge={numberOfCartItems} />
              <span className="bottom-nav__label">Cart</span>
            </Link>
          </div>
        ) : (
          <div className="bottom-nav" id="bottombar">
            <Link to="/locations" className="bottom-nav__action">
              <i className="material-icons">restaurant_menu</i>
              <span className="bottom-nav__label">Locations</span>
            </Link>
            <Link to={rh.show_location({location_id: fndLocation.slug}, Rails)} className="bottom-nav__action">
              <i className="material-icons">location_on</i>
              <span className="bottom-nav__label">Menu</span>
            </Link>
            {!!fndLocation && fndLocation.pages && fndLocation.pages.map(page => (
              <Link to={rh.location_page({location_id: fndLocation.slug, page_id: page.slug}, Rails)} key={`hol-${page.id}`}>
                <span className="bottom-nav__label">{page.title}</span>
              </Link>
            ))}
            {!!fndLocation && fndLocation.pages && (
              <>
                <Link to={rh.location_blogs({location_id: fndLocation.slug}, Rails)} className="bottom-nav__action">
                  <i className={`material-icons ${fndLocation.blogs_count > 0 ? 'badge1' : ''}`} data-badge={fndLocation.blogs_count}>rss_feed</i>
                  <span className="bottom-nav__label">Blogs</span>
                </Link>
                <Link to={rh.location_reviews({location_id: fndLocation.slug}, Rails)} className="bottom-nav__action">
                  <i className={`material-icons ${fndLocation.reviews_count > 0 ? 'badge1' : ''}`} data-badge={fndLocation.reviews_count}>rate_review</i>
                  <span className="bottom-nav__label">Reviews</span>
                </Link>
              </>
            )}
            <Link to={rh.location_cart({location_id: fndLocation.slug}, Rails)} className="bottom-nav__action">
              <i className={`fas fa-shopping-cart ${basket.totals.numberOfCartItems > 0 ? 'badge1' : ''}`} data-badge={basket.totals.numberOfCartItems}></i>
              <span className="bottom-nav__label">Cart</span>
            </Link>
          </div>
        )}        
      </Hidden>
      <Hidden smDown={true}>
        <div className="footer hide-on-small-only clr-dark" style={{ width: '100%', bottom: '0' }}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={3} sm={2}>
                <a href="/">
                  <img className="responsive-img footer-logo" alt="Foodnerd" src={loadImage('logo-nav.png')} />
                </a>
              </Grid>
              <Grid item xs={3} sm={3}>
                <h4 className="white-text no-margin" style={{ fontSize: '1.64rem' }}>{I18nRails.t("footer.learn_more")}</h4>
                <List style={{ color: 'grey' }}>
                  <ListItem>
                    <a className="grey-text text-lighten-3" href="/aboutus">About Us</a>
                  </ListItem>
                  <ListItem>
                    <a className="grey-text text-lighten-3" href="/rider_profile/new">{I18nRails.t("footer.become_a_rider")}</a>
                  </ListItem>
                  <ListItem>
                    <a className="grey-text text-lighten-3" href="/blogs">{I18nRails.t("footer.blog")}</a>
                    <a href="/blogs"></a>
                  </ListItem>
                  <ListItem>
                    <a className="grey-text text-lighten-3" href="/customer-feedback">{I18nRails.t("footer.customer-feedback")}</a>
                    <a href="/customer-feedback"></a>
                  </ListItem>
                  <ListItem>
                    <a className="grey-text text-lighten-3" href="/become-rider-benefits">{I18nRails.t("footer.become-rider-benefits")}</a>
                    <a href="/become-rider-benefits"></a>
                  </ListItem>
                  <ListItem>
                    <a className="grey-text text-lighten-3" href="/gift-vouchers">{I18nRails.t("footer.gift-vouchers")}</a>
                    <a href="/gift-vouchers"></a>
                  </ListItem>
                  <ListItem>
                    <a className="grey-text text-lighten-3" href="/how-to-order">{I18nRails.t("footer.how-to-order")}</a>
                    <a href="/how-to-order"></a>
                  </ListItem>
                  <ListItem>
                    <a className="grey-text text-lighten-3" href="/faq">FAQ</a>
                  </ListItem>
                  {fndLocation && fndLocation.pages.map((page) => (
                    <ListItem key={'hom-' + page.id}>
                      <Link to={rh.location_page({ location_id: fndLocation.slug, page_id: page.slug }, Rails)}>
                        {page.title}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={3} sm={3}>
                <h4 className="white-text no-margin" style={{fontSize: '1.64rem'}}> {I18nRails.t("footer.company")} </h4>
                <List style={{color: 'grey'}}>
                  <ListItem>
                    <a href='/contact'> {I18nRails.t("footer.contact")} </a>
                  </ListItem>
                  <ListItem>
                    <a href='/careers'> {I18nRails.t("footer.careers")} </a>
                  </ListItem>
                  <ListItem>
                    <a className="grey-text text-lighten-3" href='/privacypolicy'> Privacy Policy </a>
                  </ListItem>
                  <ListItem>
                    <a className="grey-text text-lighten-3" href='/termsofuse'> Terms of use </a>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={3} sm={3}>
                <h4 className="white-text no-margin" style={{fontSize:'1.64rem'}}>
                  { I18nRails.t('business_dashboard.footer.follow_us') }
                </h4>
                <div id="social">
                  <a className="smGlobalBtn" href={countryConfig.facebook}>
                    <i className="fab fa-facebook-f white-text"></i>
                  </a>
                  <a className="smGlobalBtn" href={countryConfig.twitter}>
                    <i className="fab fa-twitter white-text"></i>
                  </a>
                  <a className="smGlobalBtn" href={countryConfig.instagram}>
                    <i className="fab fa-instagram white-text"></i>
                  </a>
                </div>
              </Grid>
            </Grid>
          </Container>
          <Grid item xs={12} sm={12}>
            <div className="footer-copyright grey darken-3">
              <Container className="center">
                <p style={{textAlign: "center"}}>
                  © {new Date().getFullYear()} <a href={Rails.host}>Foodnerd</a> {I18nRails.t("footer.rights_reserved")}
                </p>
              </Container>
            </div>
          </Grid>
        </div>
      </Hidden>
    </>                    
  );
    // return pug`
    //   Hidden(mdUp=true)
    //     if !basket || !fndLocation
    //       .bottom-nav#bottombar
    //         Link(to='/locations', className='bottom-nav__action')
    //           i.material-icons restaurant_menu
    //           span.bottom-nav__label ${ I18nRails.t("footer.locations") }
    //         Link(to='/favorites', className='bottom-nav__action')
    //           i.material-icons favorite
    //           span.bottom-nav__label Favorites
    //         Link(to='/orders', className='bottom-nav__action')
    //           i.material-icons list
    //           span.bottom-nav__label Recent Orders
    //         Link(to='/cart', className='bottom-nav__action')
    //           i.fas.fa-shopping-cart(data-badge=numberOfCartItems, className=numberOfCartItems > 0 ? 'badge1': '')
    //           span.bottom-nav__label Cart
    //     else 
    //       .bottom-nav#bottombar
    //         Link(to='/locations', className='bottom-nav__action')
    //           i.material-icons restaurant_menu
    //           span.bottom-nav__label Locations
    //         Link(to=rh.show_location({location_id: fndLocation.slug}, Rails), className='bottom-nav__action')
    //           i.material-icons location_on
    //           span.bottom-nav__label Menu
    //         if !!fndLocation && fndLocation.pages
    //           each page in fndLocation.pages
    //             Link(to=rh.location_page({location_id: fndLocation.slug, page_id: page.slug}, Rails), key='hol-'+page.id) 
    //               span.bottom-nav__label= page.title
    //           Link(to=rh.location_blogs({location_id: fndLocation.slug}, Rails), className='bottom-nav__action')
    //             i.material-icons(data-badge=fndLocation.blogs_count, className=fndLocation.blogs_count > 0 ? 'badge1': '') rss_feed
    //             span.bottom-nav__label Blogs
    //           Link(to=rh.location_reviews({location_id: fndLocation.slug}, Rails), className='bottom-nav__action')
    //             i.material-icons(data-badge=fndLocation.reviews_count, className=fndLocation.reviews_count > 0 ? 'badge1': '') rate_review
    //             span.bottom-nav__label Reviews
    //         Link(to=rh.location_cart({location_id: fndLocation.slug}, Rails), className='bottom-nav__action')
    //           i.fas.fa-shopping-cart(data-badge=basket.totals.numberOfCartItems, className=basket.totals.numberOfCartItems > 0 ? 'badge1': '')
    //           span.bottom-nav__label Cart
    //   Hidden(smDown=true)
    //     .footer.hide-on-small-only.clr-dark(style={width:'100%', bottom:'0' })
    //       Container(maxWidth="lg")
    //         Grid(container) 
    //           Grid(item xs=3 sm=2)
    //            a(href="/")
    //              img.responsive-img.footer-logo(alt='Foodnerd', src=this.loadImage('logo-nav.png'))
    //           Grid(item xs=3 sm=3)
    //             h4.white-text.no-margin(style={fontSize:'1.64rem'}) ${ I18nRails.t("footer.learn_more") }
  //             List(style={color:'grey'})
  //               ListItem
  //                   a.grey-text.text-lighten-3(href='/aboutus')  About Us
  //               ListItem
  //                   a.grey-text.text-lighten-3(href='/rider_profile/new')  ${ I18nRails.t("footer.become_a_rider") }
  //               ListItem
  //                 a.grey-text.text-lighten-3(href='/blogs') ${ I18nRails.t("footer.blog") }
  //                 a(href='/blogs') 

  //               ListItem
  //                 a.grey-text.text-lighten-3(href='/customer-feedback') ${ I18nRails.t("footer.customer-feedback") }
  //                 a(href='/customer-feedback')

  //               ListItem
  //                 a.grey-text.text-lighten-3(href='/become-rider-benefits') ${ I18nRails.t("footer.become-rider-benefits") }
  //                 a(href='/become-rider-benefits')
                
  //               ListItem
  //                 a.grey-text.text-lighten-3(href='/gift-vouchers') ${ I18nRails.t("footer.gift-vouchers") }
  //                 a(href='/gift-vouchers')  
                
  //               ListItem
  //               a.grey-text.text-lighten-3(href='/how-to-order') ${ I18nRails.t("footer.how-to-order") }
  //               a(href='/how-to-order')
                
  //               ListItem
  //                 a.grey-text.text-lighten-3(href='/faq')  FAQ
  //               if !!fndLocation && fndLocation.pages
  //                 each page in fndLocation.pages
  //                   ListItem(key='hom-'+page.id)
  //                     Link(to=rh.location_page({location_id: fndLocation.slug, page_id: page.slug}, Rails)) 
  //                       = page.title
  //           Grid(item xs=3 sm=3)
  //             h4.white-text.no-margin(style={fontSize:'1.64rem'})  ${ I18nRails.t("footer.company") }
  //             List(style={color:'grey'})
  //               ListItem
  //                 a(href='/contact')  ${ I18nRails.t("footer.contact") }
  //               ListItem
  //                 a(href='/careers') ${ I18nRails.t("footer.careers") }
  //               ListItem
  //                 a.grey-text.text-lighten-3(href='/privacypolicy')  Privacy Policy
  //               ListItem
  //                 a.grey-text.text-lighten-3(href='/termsofuse')  Terms of use
  //           Grid(item xs=3 sm=3)
  //             h4.white-text.no-margin(style={fontSize:'1.64rem'})= I18nRails.t('business_dashboard.footer.follow_us')
  //             #social
  //               a.smGlobalBtn(href=countryConfig.facebook)
  //                 i.fab.fa-facebook-f.white-text
  //               a.smGlobalBtn(href=countryConfig.twitter)
  //                 i.fab.fa-twitter.white-text
  //               a.smGlobalBtn(href=countryConfig.instagram)
  //                 i.fab.fa-instagram.white-text
  //       Grid(item xs=12 sm=12)
  //         .footer-copyright.grey.darken-3
  //           Container.center
  //             p(style={textAlign:"center"})
  //               | © #{new Date().getFullYear()} 
  //               a(href=Rails.host) Foodnerd
  //               |  ${ I18nRails.t("footer.rights_reserved") }
  //     `;
}

export default AppFooter;
