import { createCipher, createDecipher} from 'crypto-browserify' 

const base64UrlToBuffer = (base64Url) => {
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const padding = '='.repeat((4 - (base64.length % 4)) % 4);
  const base64Padded = base64 + padding;
  return Buffer.from(base64Padded, 'base64');
};

const encryptData = (payload, keyHex) => {
  const key = base64UrlToBuffer(keyHex);
  const jsonString = JSON.stringify(payload);
  const algorithm = "aes-256-cbc";
  const cipher = createCipher(algorithm, key);
  let encrypted = cipher.update(jsonString, "utf8", "hex");
  encrypted += cipher.final("hex");
 return encrypted
};

const decryptData = (encryptedObj, keyHex) => {
  const algorithm = 'aes-256-cbc';
  const key = base64UrlToBuffer(keyHex)
  const encryptedData = encryptedObj;
  const decipher = createDecipher(algorithm, key);
  let decrypted = decipher.update(encryptedData, 'hex', 'utf8');
  decrypted += decipher.final('utf8');
  return JSON.parse(decrypted);
};

export {
    encryptData,
    decryptData
}