export default class GeolocationFactory {
  constructor(Rails) {
    this.Rails = Rails;
  }

  get(callback) {
    //    if !!window.cordova
    //      posOptions =
    //        timeout: 10000
    //        enableHighAccuracy: false
    //      try
    //        cordovaGeolocation = $injector.get('$cordovaGeolocation')
    //      catch e
    //        Sentry.captureException(e)
    //        cordovaGeolocation = $injector.get('geolocation')
    //      if !!cordovaGeolocation.getCurrentPosition
    //        cordovaGeolocation.getCurrentPosition(posOptions).then(((data) ->
    //          callback(lat: data.coords.latitude, lng: data.coords.longitude)
    //          return
    //        ), (err) ->
    //          console.log("Unable to get coordinates")
    //          callback(lat: 0, lng: 0)
    //          return
    //        )
    //      else
    //        callback(lat: 0, lng: 0)
    //        return
    //    else
    //    if Rails.env == 'test'
    //      callback(lat: 0, lng: 0)
    //      return

    const forced_response = setTimeout(
      () => callback({ lat: 0, lng: 0 })
      , 5000
    );

    return navigator.geolocation.getCurrentPosition(
      function(position) {
        clearTimeout(forced_response);
        callback({ lat: position.coords.latitude, lng: position.coords.longitude });

      },
      function(error) {
        console.error(error);
        callback({ lat: 0, lng: 0 });
      });
  }
}
