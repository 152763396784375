import React, { useState, useRef } from "react";

import OrderResource from "../../resources/order";

import * as rh from "../helpers/routes";
import { useNavigate } from "react-router";

const withOrder =
  (Component) =>
  ({ Rails, cartService, ...other }) => {
    const navigate = useNavigate();

    const [order, setOrder] = useState();
    const [doListenForUpdates, setDoListenForUpdates] = useState();

    const listenForUpdate = (order_id) => {
      console.log("TODO implement this via firebase");
      setTimeout(() => {
        const Order = OrderResource.get(Rails);
        Order.get(
          { id: order_id },
          (order) => {
            setOrder(order);
            listenForUpdate(order.id);
          },
          (failure) => {
            alert("Error fetching order. Please refresh");
          }
        );
      }, 10000);
    };

    const orderStateColor = (state) => {
      switch (state) {
        case "received":
          return "yellow";
        case "rejected":
          return "red";
        case "accepted":
          return "green";
        default:
          return "blue";
      }
    };

    const cancelOrder = (order) => {
      const order_params = {
        action: "cancelled",
        annotation: "Cancelled By User.",
      };
      const Order = OrderResource.get(Rails);
      Order.update(
        { id: order.id },
        { order: order_params },
        (response) => {
          navigate(rh.show_location({ location_id: order.location.slug }));
        }
      );
    };

    const reorder = (order) => {
      //loop through the order items and put them into the checkout basket, then redirect user to checkout
      //      if CartService.getLocation() != null
      //        if CartService.getLocation().id != order.location_id
      //          $translate('venue.alertmessage.onelocation').then(
      //            (success) ->
      //              sweetalert2.fire("#{success}" )
      //          )
      //          return
      for (let order_item of order.order_items) {
        if (order_item.item_type === "MenuItem") {
          const success = cartService.addOrderItem(order.location, order_item);
        }
      }

      setDoListenForUpdates(false);
      navigate(rh.location_cart({ location_id: order.location.slug }));
    };

    return (
      <Component
        Rails={Rails}
        cartService={cartService}
        order={order}
        setOrder={setOrder}
        doListenForUpdates={doListenForUpdates}
        setDoListenForUpdates={setDoListenForUpdates}
        listenForUpdate={listenForUpdate}
        orderStateColor={orderStateColor}
        cancelOrder={cancelOrder}
        reorder={reorder}
        {...other}
      />
    );
  };

export default withOrder;
