export const userFullname = function(user) {
  return [user.first_name, user.last_name].join(" ");
};

export const isUserAdmin = function(user) {
  return user.admin;
};

export const isUserManager = function(user, location) {
  if (!!location) {
    return user.managed_location_ids?.includes(location.id);
  }
};

export const userLikes = function(user) {
  
  return (
    user._user_likes || (user._user_likes = processUserFavorites(user))
  );
};

export const userFriendsLikes = function(user) {
  return (
    user._user_friends_likes ||
    (user._user_friends_likes = { Location: {}, Menu: {}, MenuItem: {} })
  );
};

export const processUserFavorites = function(user) {
  const user_likes = { Location: {}, Menu: {}, MenuItem: {}, Review: {} };
  
  if (!!user.favorites) {
    for (let favorite of user.favorites) {
      favorite.obj.red = true;
      if (!user_likes[favorite.obj_type]) {
        user_likes[favorite.obj_type] = {};
      }
      user_likes[favorite.obj_type][favorite.obj_id] = favorite;
      user_likes[favorite.obj_type][favorite.obj_id].obj.red = true;
    }
  }
  return user_likes;
};

export const setUserFriendsFavorites = function(user, friends_favorites) {
  for (let favorite of friends_favorites) {
    var base, base1;
    if (!(base = userFriendsLikes(user))[favorite.obj_type]) {
      base[favorite.obj_type] = {};
    }
    if (
      !(base1 = userFriendsLikes(user))[favorite.obj_type][
      favorite.obj_id
      ]
    ) {
      base1[favorite.obj_type][favorite.obj_id] = {};
    }
    userFriendsLikes(user)[favorite.obj_type][favorite.obj_id]["likes"] =
      favorite.friends_likes;
    userFriendsLikes(user)[favorite.obj_type][favorite.obj_id][
      "friends"
    ] = {};
  }
  return (user.friends_favorites = friends_favorites);
};

export const setUserLocationsFriends = function(user, locations_friends) {
  for (let location_friend of locations_friends) {
    for (let favorite of location_friend.favorites) {
      var base, base1, base2;
      if (!(base = userFriendsLikes(user))[favorite.obj_type]) {
        base[favorite.obj_type] = {};
      }
      if (
        !(base1 = userFriendsLikes(user))[favorite.obj_type][
        favorite.obj_id
        ]
      ) {
        base1[favorite.obj_type][favorite.obj_id] = {};
      }
      if (
        !(base2 = userFriendsLikes(user))[favorite.obj_type][
        favorite.obj_id
        ]["friends"]
      ) {
        base2[favorite.obj_type][favorite.obj_id]["friends"] = {};
      }
      if (
        Object.keys(
          userFriendsLikes(user)[favorite.obj_type][favorite.obj_id][
          "friends"
          ]
        ).length < 5
      ) {
        userFriendsLikes(user)[favorite.obj_type][favorite.obj_id][
          "friends"
        ][location_friend.id] = {
          name: location_friend.name,
          picture: location_friend.picture,
        };
      }
    }
  }

  return (user.locations_friends = locations_friends);
};

export const userFriendsWhoLikeLocation = function(user, location) {
  if (!!userFriendsLikes(user)["Location"][location.id]) {
    return userFriendsLikes(user)["Location"][location.id]["friends"];
  } else {
    return null;
  }
};

export const getUserFriendsWhoLikeMenu = function(user, menu) {
  return userFriendsLikes(user)["Menu"][menu.id]["friends"];
};

export const getUserFriendsWhoLikeMenuItem = function(user, menu_item) {
  return userFriendsLikes(user)["MenuItem"][menu_item.id]["friends"];
};

export const getUserFriendsLikesForLocation = function(user, location) {
  if (!location) {
    return 0;
  }
  const user_friends_likes = userFriendsLikes(user);
  if (!user_friends_likes["Location"]) {
    return 0;
  }
  if (!!user_friends_likes["Location"][location.id]) {
    return user_friends_likes["Location"][location.id]["likes"] || 0;
  } else {
    return 0;
  }
};

export const getUserFriendsLikesForMenu = function(user, menu) {
  if (!menu) {
    return 0;
  }
  const user_friends_likes = userFriendsLikes(user);
  if (!user_friends_likes["Menu"]) {
    return 0;
  }
  if (!!user_friends_likes["Menu"][menu.id]) {
    return user_friends_likes["Menu"][menu.id]["likes"] || 0;
  } else {
    return 0;
  }
};

export const getUserFriendsLikesForMenuItem = function(user, menu_item) {
  if (!menu_item) {
    return 0;
  }
  //@user_friends_likes()['MenuItem'][menu_item.id]['likes'] || 0
  const user_friends_likes = userFriendsLikes(user);
  if (!!user_friends_likes["MenuItem"][menu_item.id]) {
    return user_friends_likes["MenuItem"][menu_item.id]["likes"] || 0;
  } else {
    return 0;
  }
};

export const isUserLiked = function(user, likeable, likeable_type) {
  
  if (!!likeable) {
    return userLikes(user)[likeable_type][likeable.id];
  }
};

export const setUserLike = function(user, likeable, likeable_type) {
  
  FavoriteService.like(likeable, likeable_type, Rails);
  //TODO add to array should happen on success of service
  let _user_likes = userLikes(user);
  if (!_user_likes[likeable_type]) {
    _user_likes[likeable_type] = {};
  }
  _user_likes[likeable_type][likeable.id] = {
    obj_type: likeable_type,
    obj_id: likeable.id,
    obj: likeable,
  };
  
  _user_likes[likeable_type][likeable.id].obj.red = true;

  let favorite = _user_likes[likeable_type][likeable.id];
  user.favorites.push(favorite);
  return favorite;
};

export const setUserUnlike = function(user, likeable, likeable_type) {
  FavoriteService.unlike(likeable, likeable_type, Rails);
  //TODO delete from array should happen on success of service
  let favorite = userLikes(user)[likeable_type][likeable.id];
  delete userLikes(user)[likeable_type][likeable.id];
  user.favorites.splice([user.favorites.indexOf(favorite)], 1);
};

export const userAvatar = function(user) {
  if (!!user.user_image_url) {
    return user.user_image_url;
  } else {
    if (!!user.image_url_large) {
      return user.image_url_large;
    } else {
      return Rails.default_user_avatar;
    }
  }
};
export const isUserTokenSet = function(user) {
  return user.token_status === "set";
};

export const isUserTokenSaved = function(user) {
  return user.token_status === "saved";
};

