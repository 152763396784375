import React from "react";
import { format, utcToZonedTime } from "date-fns-tz";
import { Helmet } from "react-helmet";
import { JsonLd } from "react-schemaorg";
import { location_address, location_logo_url } from "../../models/location";

export const getLocationMetaTags =  ({location}) => {
  let seo_keywords =
    +location.meta_keywords ||
    "Our Locations, Food Delivery Service in Pakistan " +
    location.name +
    " ," +
    location.meta_keywords +
    " , food delivery in pakistan, food delivery in islamabad, food delivery near me, food shopping online, food supplies, cash on delivery,food online, tasty, home delivery,";
  let seo_description =
    "Foodnerd is the best food delivery service in Lahore, Islamabad, and Rawalpindi. These are our main location but we are expending SOON! " +
    location.meta_description;
  let seo_title =
    "Our Locations, Food Delivery Service in Pakistan | Foodnerd";
  var dateTime = "";
  for (let i = 0; i < location.weekly_schedules.length; i++) {
    let startTime = utcToZonedTime(
      location.weekly_schedules[i].start_time,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    let endTime = utcToZonedTime(
      location.weekly_schedules[i].end_time,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    if (location.weekly_schedules[i].day_of_week == 1) {
      dateTime +=
        "Mo " + format(startTime, "HH:MM") + "-" + format(endTime, "HH:MM");
    }
    if (location.weekly_schedules[i].day_of_week == 2) {
      dateTime +=
        ",Tu " +
        format(startTime, "HH:MM") +
        "-" +
        format(endTime, "HH:MM");
    }
    if (location.weekly_schedules[i].day_of_week == 3) {
      dateTime +=
        ",We " +
        format(startTime, "HH:MM") +
        "-" +
        format(endTime, "HH:MM");
    }
    if (location.weekly_schedules[i].day_of_week == 4) {
      dateTime +=
        ",Th " +
        format(startTime, "HH:MM") +
        "-" +
        format(endTime, "HH:MM");
    }
    if (location.weekly_schedules[i].day_of_week == 5) {
      dateTime +=
        ",Fr " +
        format(startTime, "HH:MM") +
        "-" +
        format(endTime, "HH:MM");
    }
    if (location.weekly_schedules[i].day_of_week == 6) {
      dateTime +=
        ",Sa " +
        format(startTime, "HH:MM") +
        "-" +
        format(endTime, "HH:MM");
    }
    if (location.weekly_schedules[i].day_of_week == 7) {
      dateTime +=
        ",Su " +
        format(startTime, "HH:MM") +
        "-" +
        format(endTime, "HH:MM");
    }
  }
  var menuSectionArr = [];
  location.menus.map(function(menu) {
    var m = {
      "@type": "MenuSection",
      name: menu.title,
      hasMenuSection: [],
    };
    m.hasMenuSection = menu.menu_items.map((item) => ({
      hasMenuItem: {
        "@type": "MenuItem",
        name: item.title,
      },
    }));
    menuSectionArr.push(m);
  });
  if (seo_keywords) {
    const locationAddress = location_address(location)
    return (
      <Helmet
        script={[
          JsonLd({
            "@context": "https://schema.org",
            "@type": "Restaurant",
            name: location.name,
            telephone: location.contact,
            image: location.img_url,
            openingHours: location._timeToday,
            address: {
              "@type": "PostalAddress",
              streetAddress: locationAddress.street,
              addressLocality: locationAddress.local_address,
              addressRegion: locationAddress.country,
              postalCode: locationAddress.zip,
            },
            alternateName: location.name,
            description: { seo_description },
            keywords: seo_keywords,
            hasMenu: {
              type: "@Menu",
              hasMenuSection: menuSectionArr,
            },
          }),
        ]}
      >
        <title>{location.name}</title>
        <meta name="description" content={seo_description} />
        <meta name="keywords" content={seo_keywords} />
        <meta property="openingHours" content={dateTime} />
        <meta property="alternateName" content={location.name} />
        <meta property="og:title" content={location.name} />
        <meta name="title" content={seo_title} />
        <meta property="og:title" content={location.name} />
        <meta property="og:description" content={seo_description} />
        <meta property="og:image" content={location.img_url} />
        <link property="image_src" href={location.img_url} />
        <link property="logo" href={location_logo_url(location)} />
        <meta name="twitter:title" content={location.name} />
        <meta name="twitter:description" content={seo_description} />
        <meta name="twitter:image" content={location.img_url} />
        <meta name="smartbanner:title" content={location.name} />
      </Helmet>
    );
  } else {
    return null;
  }
};