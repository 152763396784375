/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import React from 'react';
import { GoogleMapProvider, MapBox, Marker } from '@googlemap-react/core';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { I18nRails } from '../../../shared/rails-i18n-js';
import { locationOperationHours, location_address } from '../../models/location';

const template = ({
  fndLocation,
  viewHours,
  closeHours,
  setLatLng,
  unmappedAddress
}) => {
  var address = location_address(fndLocation);
  var operationHours = locationOperationHours(fndLocation);
  return (
    <Dialog open={viewHours} fullWidth={true} maxWidth='xl'>
      <DialogTitle style={{ paddingBottom: 0, paddingTop: 3, paddingRight: 0 }}>
        <Grid container>
          <Grid xs={11} item />
          <Grid xs={1} item style={{ textAlign: 'right' }}>
            <IconButton aria-label="close" onClick={(e) => closeHours()}>
              <CloseIcon style={{ color: '#ef757f' }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container>
          {fndLocation && (
            <Grid item xs={6}>
              <h4 className="first-color subhead-font" style={{ marginBottom: 1 }}>
                {fndLocation.name}
              </h4>
              {address ? (
                <div style={{ height: '400px', padding: '4%' }}>
                  <GoogleMapProvider>
                    <MapBox
                      apiKey={process.env.GOOGLE_GEOCODE_API_KEY}
                      opts={{ center: { lat: address.lat, lng: address.lng }, zoom: 12 }}
                      onClick={(e) => setLatLng(e, unmappedAddress)}
                    />
                    <Marker
                      opts={{ label: fndLocation.name, draggable: false, position: { lat: address.lat, lng: address.lng } }}
                      onDragend={(e) => setLatLng(e, unmappedAddress)}
                    />
                  </GoogleMapProvider>
                </div>
              ) : (
                <CircularProgress />
              )}
            </Grid>
          )}
          {fndLocation && (
            <Grid item xs={6}>
              <p>{fndLocation.description}</p>
              <p className="first-color subhead-font">Address</p>
              {fndLocation.addresses && (
                <div>
                  <p>{address.full_address}</p>
                </div>
              )}
              <Grid container>
                <Grid item xs={12}>
                  <p className="first-color subhead-font">{I18nRails.t('Search.LocationShow.openinghours')}</p>
                  {operationHours.map((hours_of_operation, index) => (
                    <div key={index}>
                      <Grid container>
                        <Grid item xs={4} style={{ padding: '0' }}>
                          {hours_of_operation.split('day')[0]}day
                        </Grid>
                        <Grid item xs={8}>{hours_of_operation.split('day')[1]}</Grid>
                      </Grid>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
  
  // return pug`
  // Dialog(open=viewHours fullWidth=true maxWidth='xl')
  //   DialogTitle(style={paddingBottom:0, paddingTop:3, paddingRight:0})
  //     Grid(container)
  //       Grid(xs=11 item)
  //       Grid(xs=1 item style={textAlign:'right'})
  //         IconButton(aria-label="close" onClick=(e)=>closeHours())
  //           CloseIcon(style={color:'#ef757f'})
  //   DialogContent(style={paddingTop:0})
  //     Grid(container)
  //       if !!fndLocation
  //         Grid(item xs=6)
  //           h4.first-color.subhead-font(style={marginBottom:1}) ${ fndLocation.name }
  //           - var address = fndLocation.address()
  //           if address 
  //             div(style={height:'400px', padding:'4%'})  
  //               GoogleMapProvider
  //                 MapBox(apiKey=process.env.GOOGLE_GEOCODE_API_KEY, opts={center: {lat: address.lat, lng: address.lng}, zoom: 12}, onClick=(e) => setLatLng(e, unmappedAddress))
  //                 Marker(opts={label: fndLocation.name, draggable: false, position: {lat: address.lat, lng: address.lng}}, onDragend=(e) => setLatLng(e, unmappedAddress))
  //           else 
  //             CircularProgress
  //         Grid(item xs=6)
  //           p ${ fndLocation.description }
  //           p.first-color.subhead-font Address
  //           if !!fndLocation.addresses
  //             - var address = fndLocation.address()
  //             p= address.full_address
  //           Grid(container)
  //             Grid(item xs=12)
  //               p.first-color.subhead-font ${ I18nRails.t('Search.LocationShow.openinghours') }
  //               - var operationHours = fndLocation.operationHours()
  //               each hours_of_operation, index in operationHours
  //                 div(key=index)
  //                   Grid(container)
  //                     Grid(item xs=4 style={padding:"0"}) ${ hours_of_operation.split("day")[0] }day
  //                     Grid(item xs=8) ${ hours_of_operation.split("day")[1] }
     
  // `;
};

export default template;
