import React, { Component, useState } from 'react';

import { I18nRails } from '../../../shared/rails-i18n-js';

import { Grid } from '@mui/material';
import Switch from '@mui/material/Switch';

const SearchFilters = ({
  filter,
  setSearchFilters,
  user,
}) => {

  const [open_now, setOpen_now] = useState(false);
  const [local_region, setLocal_region] = useState(false);
  const [has_delivery, setHas_delivery] = useState(false);
  const [online_booking, setOnline_booking] = useState(false);
  const [friends_favorites, setFriends_favorites] = useState(false);

  const onChangeOpenNow = () => {
    setOpen_now(!open_now);
    console.log('this.filter', filter);
    setSearchFilters({ ...filter, open_now: !open_now });
  };

  const onChangeLocalRegion = () => {
    setLocal_region(!local_region);

    setSearchFilters({ ...filter, local_region: !local_region });
  };

  const onChangeHasDelivery = () => {
    setHas_delivery(!has_delivery);

    setSearchFilters({ ...filter, has_delivery: !has_delivery });
  };

  const onChangeOnlineBooking = () => {
    setOnline_booking(!online_booking);

    setSearchFilters({ ...filter, online_booking: !online_booking });
  };

  const onChangeFavFriends = () => {
    setFriends_favorites(!friends_favorites);

    setSearchFilters({ ...filter, friends_favorites: !friends_favorites });
  };

  return (
    <Grid container alignItems="center" justify="center">
      <Grid item xs={2}>
        <Switch
          checked={open_now}
          color="primary"
          onChange={onChangeOpenNow}
        />
        <label style={{ color: 'grey', fontSize: '13px' }}>
          {I18nRails.t('Web.filters.open')}
        </label>
      </Grid>
      <Grid item xs={2}>
        <Switch
          color="primary"
          checked={has_delivery}

          onChange={onChangeHasDelivery}
        />
        <label style={{ color: 'grey', fontSize: '13px' }}>
          {I18nRails.t('Web.filters.deliveries')}
        </label>
      </Grid>
      <Grid item xs={2}>
        <Switch
          checked={online_booking}
          color="primary"
          onChange={onChangeOnlineBooking}
        />
        <label style={{ color: 'grey', fontSize: '13px' }}>
          {I18nRails.t('Web.filters.online_orders')}
        </label>
      </Grid>
      <Grid item xs={2}>
        <Switch
          checked={local_region}
          color="primary"
          onChange={onChangeLocalRegion}
        />
        <label style={{ color: 'grey', fontSize: '13px' }}>
          {I18nRails.t('Web.filters.local_region')}
        </label>
      </Grid>
      {user != "undefined" ? (
        <Grid item xs={2}>
          <Switch
            checked={friends_favorites}
            color="primary"
            onChange={onChangeFavFriends}
          />
          <label style={{ color: 'grey', fontSize: '13px' }}>
            {I18nRails.t('Web.filters.friends')}
          </label>
        </Grid>
      ) : null}

    </Grid>
  );
};

export default SearchFilters;

