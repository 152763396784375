import { firebaseConfig } from "./constants";

import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";

const { init } = firebaseConfig;

if(process.env.NODE_ENV !== 'test') {
  firebase.initializeApp(init);
  // firebase.firestore().settings({
  //   cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  //   experimentalForceLongPolling: true
  // });

  // firebase
  //   .firestore()
  //   .enablePersistence()
  //   .catch((err) => {
  //     if (err.code === "failed-precondition") {
  //       // console.error("Multiple tabs open, persistence can only be enabled in one tab at a a time.");
  //     } else if (err.code === "unimplemented") {
  //       // console.error("The current browser does not support all of the features required to enable persistence");
  //     }
  //   });
}
// const firestore = firebase.firestore;
// export { firebase, firestore };
export { firebase };
