import FoodnerdResource from './FoodnerdResource';

export default class UserOrderResource {
  static get(Rails) {
    const base = `${ Rails.host }/users/me/orders`;
    const mappings = { id: ':id' };
    const Order = new FoodnerdResource(Rails.access_token, `${ base }/{:id}.json`, mappings);

    Order.prototype.applyPayment = function(paymentMethod, paymentAnnotation) {
      this.payment_methods = paymentMethod;
      return this.payment_annotation = paymentAnnotation;
    };

    return Order;
  }
}

