import FoodnerdResource from './FoodnerdResource';
export default class FavoriteResource {
  static get(Rails) {
    const mappings = { id: ':id' };
    const baseUrl = `${ Rails.host }/api/favorites`;
    const Favorite = new FoodnerdResource(Rails.access_token, `${ baseUrl }/{:id}.json`, mappings, {
      query: {
        url: `${ baseUrl }.json?obj_type=Location`
      }
    }
    );

    Favorite.prototype.small_logo_url = function() {
      if (!!this.obj && !!this.obj.hosted_datas && (this.obj.hosted_datas.length > 0)) {
        return this.obj.hosted_datas[this.obj.hosted_datas.length - 1].url_small_logo || Rails.default_location_image_path;
      }
      return Rails.default_location_image_path;
    };

    Favorite.prototype.small_logo_url_menu = function() {
      if (!!this.obj && !!this.obj.hosted_datas && (this.obj.hosted_datas.length > 0)) {
        return this.obj.hosted_datas[this.obj.hosted_datas.length - 1].url_small_logo || Rails.default_location_image_path;
      }

      return Rails.default_location_image_path;
    };

    Favorite.prototype.address = function() {
      if (!!this.obj && !!this.obj.addresses) {
        if (this.obj.addresses.length > 0) { return this.obj.addresses[0]; }
      }
    };

    Favorite.prototype.getDistance = function(coords) {
      const R = 6371;// // Radius of the earth in km
      const location_coords = this.address();

      if (!location_coords || !coords) {
        return '';
      }

      const dLat = (coords.lat - location_coords.lat) * (Math.PI / 180);
      const dLng = (coords.lng - location_coords.lng) * (Math.PI / 180);
      const a =
        (Math.sin(dLat / 2) * Math.sin(dLat / 2)) +
        (Math.cos((location_coords.lat) * (Math.PI / 180)) * Math.cos((coords.lat) * (Math.PI / 180)) *
          Math.sin(dLng / 2) * Math.sin(dLng / 2));

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;// // Distance in km
      return Math.round(d).toString() + " km";
    };

    return Favorite;
  }
}
