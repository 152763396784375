import axios from 'axios';
import ApplicationCacheFactory from './ApplicationCacheFactory';
export default class FavoriteService {
  static ajaxHeaders(Rails) {
    const headers = { 'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone };
    if (!!Rails.access_token) { headers['Authorization'] = `Bearer ${ Rails.access_token }`; }
    return headers;
  }

  static location_friends_favorites(location_id, Rails) {
    return axios.get(`/api/favorites/location_friends_favorites/${ location_id }`, { headers: this.ajaxHeaders(Rails) });//,{cache: ApplicationCacheFactory.get()})
  }

  static locations_friends_favorites(location_ids, Rails) {
    return axios.get(`/api/favorites/locations_friends_favorites/${ location_ids }`, { headers: this.ajaxHeaders(Rails) });//,{cache: ApplicationCacheFactory.get()})
  }

  static location_friends(location_id, Rails) {
    return axios.get(`/api/favorites/location_friends/${ location_id }`, { headers: this.ajaxHeaders(Rails) });//,{cache: ApplicationCacheFactory.get()})
  }

  static locations_friends(location_ids, Rails) {
    return axios.get(`/api/favorites/locations_friends/${ location_ids }`, { headers: this.ajaxHeaders(Rails) });//,{cache: ApplicationCacheFactory.get()})
  }

  static like(likeable, likeable_type, Rails) {
    const params = {};
    params.obj_id = likeable.id;
    params.obj_type = likeable_type;
    return axios.post("/api/favorites", params, { headers: this.ajaxHeaders(Rails) }).then(
      function(response) {
        const remote_likeable = response.data.obj;
        // likeable.favorites_count = remote_likeable.favorites_count;
        //likeable.favorites_count = parseInt(likeable.favorites_count) + 1
        remote_likeable.red = true;

        return remote_likeable;
      }).catch(function(error) {
        console.error("like error", error);
      });
  }

  static unlike(likeable, likeable_type, Rails) {
    const params = {};
    params.obj_id = likeable.id;
    params.obj_type = likeable_type;
    return axios.delete(`/api/favorites/${ likeable.id }`, { params, headers: this.ajaxHeaders(Rails) }).then(
      function(response) {
        var remote_likeable = response.data.obj;
        remote_likeable.red = false;
        return remote_likeable;
      }).catch(function(error) {
        console.error("unlike error", error);
      });
  }
}

