export default class AccessTokenFactory {
  constructor(Rails) {
    this.Rails = Rails;
  }

  get() {
    return this.Rails.access_token;
  }

  set(token) {
    return this.Rails.access_token = token;
  }

  delete() {
    this.Rails.access_token = null;
  }
}

