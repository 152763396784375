import React, { useState, useEffect, useRef } from "react";
import Alert from '@mui/lab/Alert';
import Grid from '@mui/material/Grid';

import CartService from "../services/CartService";

import AppHeader from "./Headers/AppHeader";
import AppSideNav from "./AppSideNav";
import AppFooter from "./Footers/AppFooter";

const AppLayout = ({
  Rails,
  basket,
  user,
  selectedCountry,
  changeCountry,
  countryConfig,
  children,
  ...others
}) => {
  const [isSideNavOpened, setIsSideNavOpened] = useState(false);
  const [hideFlash, setHideFlash] = useState({});
  const [flashTime, setFlashTime] = useState();

  const cartServiceRef = useRef(CartService.get(Rails));

  useEffect(() => {
    if (!!Rails.flashTime) {
      if ((typeof window !== 'undefined') && ("localStorage" in window)) {
        let flashTime = window.localStorage.getItem('flashTime');
        if (!flashTime || flashTime !== Rails.flashTime) {
          setFlashTime(Rails.flashTime)
        }
        window.localStorage.setItem('flashTime', Rails.flashTime);
      }
    }
  })

  const handleAppSideNav = () => {
    setIsSideNavOpened(!isSideNavOpened);
  };

  const onFlashClose = (msg_type_index) => {
    let updatedHideFlash = {...hideFlash}
    updatedHideFlash[msg_type_index] = true;
    setHideFlash(updatedHideFlash);
  };

  const numberOfCartItems = (!!basket && !!basket.totals) ? basket.totals.numberOfCartItems : cartServiceRef.current.numberOfItems();
  const { flashes: flashes } = Rails;
  return <>
    <AppHeader 
      Rails={Rails}
      numberOfCartItems={numberOfCartItems} 
      handleAppSideNav={handleAppSideNav} 
      user={user} 
      {...others} 
    />
    <AppSideNav 
      Rails={Rails}
      numberOfCartItems={numberOfCartItems} 
      handleAppSideNav={handleAppSideNav} 
      isSideNavOpened={isSideNavOpened} 
      countryConfig={countryConfig}
      selectedCountry={selectedCountry}
      changeCountry={changeCountry}
      user={user} 
      {...others} 
    />
    <Grid container>
      {children}
      <Grid item xs={12}>
        {!!flashes && Rails.flashTime === flashTime && Object.keys(flashes).map((msg_type) => {
          const messages = flashes[msg_type];
          return messages.map((message, index) => {
            if (!hideFlash[msg_type + index]) {
              return <>
                <br />
                <Alert severity={msg_type} onClose={() => { onFlashClose(msg_type + index) }}>{message}</Alert>
              </>;
            } else {
              return null;
            }
          });
        })}
      </Grid>
      <Grid item xs={12}>
        <AppFooter 
          Rails={Rails}
          // fndLocation={}
          basket={basket}
          numberOfCartItems={numberOfCartItems}
          countryConfig={countryConfig}              
        />
      </Grid>
    </Grid>
  </>;
  //   return pug`
  //     AppHeader(numberOfCartItems=numberOfCartItems, handleAppSideNav=this.handleAppSideNav, user=this.user, paths=this.props.paths, ...this.props)
  //     AppSideNav(numberOfCartItems=numberOfCartItems, handleAppSideNav=this.handleAppSideNav, isSideNavOpened =this.state.isSideNavOpened, user=this.user, ...this.props)
  //     Grid(container)
  //       = this.props.children
  //       Grid(item xs=12)
  //         if !!flashes && flashTime === this.state.flashTime
  //           each msg_type in Object.keys(flashes)
  //             - let messages = flashes[msg_type]
  //             each message, index in messages
  //               if !this.state.hideFlash[msg_type+index]
  //                 br/
  //                 Alert(severity=msg_type, onClose=() => {this.onFlashClose(msg_type+index)})=message
  //       Grid(item xs=12)
  //         AppFooter(numberOfCartItems=numberOfCartItems, user=this.user, paths=this.props.paths, ...this.props)
  // `;
};

export default AppLayout;
