import React from "react";
import { Link } from "react-router-dom";
import { I18nRails } from "../../../shared/rails-i18n-js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { orderItemDisplayDescription, orderItemDisplayTitle } from "../../models/order_item";

export default function ShowCart({
  hideCheckout,
  basket,
  channel,
  setBasketField,
  verifyVoucherCode,
  changeQuantity,
  clearCart,
  proceedToCheckout,
  order
}) {
  return (
    <>
      {(!basket.location || basket.totals.numberOfCartItems < 1) ? (
        <p className="center empty-cart">
          <b>{I18nRails.t("client.basket.your_basket_is_empty")}</b>
          <br />
          <b>
            <Link style={{ color: "red" }} to={`/locations/${basket.location_id}`}>
              {I18nRails.t("client.basket.go_back_menus")}
            </Link>
          </b>
        </p>
      ) : (
        <>
          <div className="cart-table">
            <Table striped>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {I18nRails.t("client.basket.item")}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {I18nRails.t("client.basket.price")}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {I18nRails.t("client.basket.add")}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize", textAlign: "center" }}>
                    {I18nRails.t("client.basket.quantity")}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {I18nRails.t("client.basket.remove")}
                  </TableCell>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {I18nRails.t("client.basket.total_price")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {basket.totals.numberOfCartItems > 0 &&
                  basket.items.map((order_item, index) => (
                    <TableRow key={index + 'oi-homd'}>
                      {order_item.type == "OrderItem" && (
                        <>
                          <TableCell>{orderItemDisplayTitle(order_item)}
                            <sub style={{bottom: 0}}>{orderItemDisplayDescription(order_item)}</sub>
                          </TableCell>
                          <TableCell>{order_item.unit_price || order_item.menu_item.price}</TableCell>
                          <TableCell>
                            <a className="btn-order" onClick={(e) => changeQuantity(order_item, 'add')}>
                              <i className="green-text fas fa-plus-circle"></i>
                            </a>
                          </TableCell>
                          <TableCell style={{textAlign: 'center'}}>{order_item.weighted_number}</TableCell>
                          <TableCell>
                            <a className="btn-order" onClick={(e) => changeQuantity(order_item, 'remove')}>
                              <i className="red-text fas fa-minus-circle"></i>
                            </a>
                          </TableCell>
                          <TableCell>{order_item.price}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>{I18nRails.t("Web.total")}</TableCell>
                  <TableCell>{I18nRails.numberToCurrency(basket.totals.total, {unit: basket.location.currency_symbol})}</TableCell>
                </TableRow>
                {basket.totals.discount > 0 && (
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>{I18nRails.t("Web.discount")}</TableCell>
                    <TableCell>{basket.totals.discount}</TableCell>
                  </TableRow>
                )}
                {basket.totals.gst > 0 && (
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>{I18nRails.t("Web.tax")}</TableCell>
                    <TableCell>{basket.totals.gst}</TableCell>
                  </TableRow>
                )}
                {basket.totals.payment_fee_total > 0 && (
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>{I18nRails.t("Web.payment_fees")}</TableCell>
                    <TableCell>{I18nRails.numberToCurrency(basket.totals.payment_fee_total, {unit: basket.location.currency_symbol})}</TableCell>
                  </TableRow>
                )}
                {basket.totals.delivery_total > 0 && (
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <i className="material-icons center subHeadIcons second-color motorcycle"></i>
                      {I18nRails.t("Web.delivery_charges")}
                    </TableCell>
                    <TableCell>
                      {I18nRails.numberToCurrency(basket.totals.delivery_charges, {
                        unit: basket.location.currency_symbol,
                      })}
                    </TableCell>
                  </TableRow>
                )}
                {(!!basket.totals.delivery_total ||
                  !!basket.totals.gst ||
                  !!basket.totals.discount) && (
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell>{I18nRails.t("Web.grand_total")}</TableCell>
                      <TableCell>
                        {I18nRails.numberToCurrency(basket.totals.final_price, {
                          unit: basket.location.currency_symbol,
                        })}
                      </TableCell>
                    </TableRow>
                  )}

              </TableBody>
            </Table>
          </div>
          <br />
          {hideCheckout && (
            <form className="voucher-form" onSubmit={(e) => verifyVoucherCode(basket.voucher_code, basket, channel, e)}>
              <Grid container>
                <Grid item xs={2}>
                  <TextField label={I18nRails.t("Web.voucher_code")} variant="outlined" onChange={(e) => setBasketField('voucher_code', e)} value={basket.voucher_code} />
                </Grid>
                <Grid item xs={1}>
                  <Button className="btn-red" style={{width:'100%',marginLeft:'-5px', height:'56px', lineHeight:'normal'}} type='submit'>
                    {I18nRails.t("Web.activate_voucher")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
          {basket.totals.numberOfCartItems > 0 && (
            <Grid container>
              {!hideCheckout && (
                <>
                  <Grid xs={7} item>
                    <Button className="btn-red" onClick={(e) => clearCart(basket)}>
                      {I18nRails.t('client.basket.empty_basket')}
                    </Button>
                  </Grid>
                
                  <Grid xs={hideCheckout ? 12 : 5} item style={{textAlign:'right'}}>
                    <Button className="clr-dark" onClick={(e) => proceedToCheckout(basket, order)}>
                      {I18nRails.t('client.basket.order_proceed')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </>
      )}
    </>
  );
  // return pug`
  //   if !basket.location || basket.totals.numberOfCartItems < 1
  //     p.center.empty-cart
  //       b ${ I18nRails.t("client.basket.your_basket_is_empty") }
  //       br
  //       b
  //         Link(style={color:'red'} to='/locations/' + basket.location_id) ${ I18nRails.t(
  //   "client.basket.go_back_menus"
  // ) }
  //   else
  //     .cart-table
  //       Table.striped
  //         TableHead
  //           TableRow
  //             TableCell(style={textTransform: 'capitalize'}) ${ I18nRails.t(
  //   "client.basket.item"
  // ) }
  //             TableCell(style={textTransform: 'capitalize'}) ${ I18nRails.t(
  //   "client.basket.price"
  // ) }
  //             TableCell(style={textTransform: 'capitalize'}) ${ I18nRails.t(
  //   "client.basket.add"
  // ) }
  //             TableCell(style={textTransform: 'capitalize' ,textAlign:'center'}) ${ I18nRails.t(
  //   "client.basket.quantity"
  // ) }
  //             TableCell(style={textTransform: 'capitalize'}) ${ I18nRails.t(
  //   "client.basket.remove"
  // ) }
  //             TableCell(style={textTransform: 'capitalize'}) ${ I18nRails.t(
  //   "client.basket.total_price"
  // ) }
  //         TableBody
  //           if basket.totals.numberOfCartItems > 0
  //             each order_item, index in basket.items
  //               TableRow(key=index+'oi-homd')
  //                 if order_item.type == "OrderItem"
  //                   TableCell ${ order_item.displayTitle() } 
  //                     sub(style={bottom: 0}) ${ orderItemDisplayDescription(order_item) }
  //                   TableCell ${ order_item.unit_price ||
  //   order_item.menu_item.price }
  //                   TableCell
  //                     a.btn-order(onClick=(e) => component.changeQuantity(order_item,'add'))
  //                       i.green-text.fas.fa-plus-circle
  //                   TableCell(style={textAlign:'center'}) ${ order_item.weighted_number }
  //                   TableCell
  //                     a.btn-order(onClick=(e) => component.changeQuantity(order_item,'remove'))
  //                       i.red-text.fas.fa-minus-circle
  //                   TableCell ${ order_item.price }
  //             TableRow
  //               TableCell  
  //               TableCell  
  //               TableCell  
  //               TableCell  
  //               TableCell ${ I18nRails.t("Web.total") }
  //               TableCell= I18nRails.numberToCurrency(basket.totals.total, {unit: basket.location.currency_symbol})
  //           if basket.totals.discount > 0
  //             TableRow
  //               TableCell  
  //               TableCell  
  //               TableCell  
  //               TableCell  
  //               TableCell ${ I18nRails.t("Web.discount") }
  //               TableCell= basket.totals.discount
  //           if basket.totals.gst > 0
  //             TableRow
  //               TableCell  
  //               TableCell  
  //               TableCell  
  //               TableCell  
  //               TableCell ${ I18nRails.t("Web.tax") }
  //               TableCell= basket.totals.gst
  //           if basket.totals.payment_fee_total > 0
  //             TableRow
  //               TableCell  
  //               TableCell  
  //               TableCell  
  //               TableCell  
  //               TableCell ${ I18nRails.t("Web.payment_fees") }
  //               TableCell= I18nRails.numberToCurrency(basket.totals.payment_fee_total, {unit: basket.location.currency_symbol})
  //           if basket.totals.delivery_total > 0
  //             TableRow
  //               TableCell  
  //               TableCell  
  //               TableCell  
  //               TableCell  
  //               TableCell
  //                 i.material-icons.center.subHeadIcons.second-color motorcycle
  //                 | ${ I18nRails.t("Web.delivery_charges") }
  //               TableCell= I18nRails.numberToCurrency(basket.totals.delivery_charges, {unit: basket.location.currency_symbol})
  //           if !!basket.totals.delivery_total || !!basket.totals.gst || !!basket.totals.discount
  //             TableRow
  //               TableCell  
  //               TableCell  
  //               TableCell  
  //               TableCell  
  //               TableCell ${ I18nRails.t("Web.grand_total") }
  //               TableCell= I18nRails.numberToCurrency(basket.totals.final_price, {unit: basket.location.currency_symbol})
  //     br
  //     if hideCheckout
  //       form.voucher-form(onSubmit=(e) => component.verifyVoucherCode(basket.voucher_code, basket, channel, e))
  //         Grid(container)
  //           Grid(item xs=2) 
  //             TextField(label=${ I18nRails.t(
  //     "Web.voucher_code"
  //   ) }, variant="outlined", onChange=(e) => component.setBasketField('voucher_code', e), value=basket.voucher_code)
  //           Grid(item xs=1) 
  //             Button.btn-red(style={width:'100%',marginLeft:'-5px', height:'56px', lineHeight:'normal'} type='submit') ${ I18nRails.t(
  //     "Web.activate_voucher"
  //   ) }
  //     if basket.totals.numberOfCartItems > 0
  //       Grid(container)
  //         if !hideCheckout
  //           Grid(xs=7 item)
  //             Button.btn-red(onClick=(e) => component.clearCart(basket))= I18nRails.t('client.basket.empty_basket')
  //           Grid(xs=5 item style={textAlign:'right'})
  //             Button.clr-dark(onClick=(e) => component.proceedToCheckout(basket))= I18nRails.t('client.basket.order_proceed') 
  // `;
};
