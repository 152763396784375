import React, { useEffect } from 'react';
import { useMatch } from 'react-router';

const AppReload = () => {
  const match = useMatch();
  useEffect(() => {
    window.location.href = match.url
  }, [])
}

export default AppReload;