/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import React from "react";

import { I18nRails } from "../../../shared/rails-i18n-js";
import {minmax, price as priceFilter} from "../../filters";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { getUserFriendsLikesForMenuItem } from "../../models/user";
import { menuItemHasRequiredOptions, menuItemHasDescription } from "../../models/menu_item";
export default function MenuItemModals({
  user,
  addToCartDialog,
  closeMenuItemModal,
  menu,
  menu_item,
  menu_item_details,
  selectedDialogItem,
  selectedRadio,
  toggleComponentOptionValue,
  fndLocation,
  ingredientsForOption,
  addMenuItemIngredient,
  selected_menu_item_ingredients,
  selected_ingredients,
  removeMenuItemIngredient,
  addToBasket,
  existsInBasket,
  error_message
}) {
  return (
    <Dialog
      open={addToCartDialog}
      maxWidth="sm"
      minWidth="sm"
      onClose={(e) => closeMenuItemModal()}
      style={{ zIndex: '9999' }}
    >
      <DialogTitle style={{ paddingBottom: '0px' }}>
        <Grid container>
          <Grid item xs={10}>
            <h5
              style={{
                fontWeight: 700,
                marginBottom: 0,
                marginTop: 6,
                width: '100%',
              }}
            >
              {menu_item?.title || ""}
            </h5>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              style={{ padding: 0 }}
              aria-label="close"
              onClick={(e) => closeMenuItemModal()}
            >
              <CloseIcon style={{ color: '#ef757f' }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {selectedDialogItem?.url_image1 != null && (
          <img
            style={{
              width: '100%',
              height: '175px',
              overFlow: 'hidden',
              objectFit: 'cover',
            }}
            src={selectedDialogItem.url_image1}
          />
        )}
        <p style={{ textAlign: 'center' }}>
          {menu_item?.description || ""}
        </p>
        {menu_item?.id && (
          <Grid container>
            {menu_item_details[menu_item.id] && 
              menu_item_details[menu_item.id].component_option_values.map((component_option_value, index_1) => (
                <Grid item xs={12} key={index_1}>
                  {component_option_value.name && (
                    <h4 style={{ fontWeight: 500, marginTop: '0', marginBottom: '1%' }}>
                      {component_option_value.name}
                    </h4>
                  )}
                  {component_option_value.available_option_values.map(
                    (available_option_value, index_2) => (
                      <Grid item xs={12} key={index_2}>
                        <RadioGroup
                          name="group2"
                          value={selectedRadio}
                          onChange={(e) =>
                            toggleComponentOptionValue(
                              menu_item,
                              available_option_value
                            )
                          }
                        >
                          <FormControlLabel
                            value={available_option_value}
                            control={<Radio />}
                            label={
                              <Typography style={{ fontSize: '13px' }}>
                                {(available_option_value.name || menu_item.title) + " " +
                                  priceFilter(
                                    available_option_value.price,
                                    true,
                                    fndLocation.currency_symbol
                                  )
                                }
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </Grid>
                    )
                  )}
                </Grid>
              ))}
              {ingredientsForOption[menu_item.id] && ingredientsForOption[menu_item.id].length > 0 && (
                <Grid container>
                  {ingredientsForOption[menu_item.id].map((ingredient_list, index_4) => (
                    <Grid xs={12} item key={index_4}>
                      {ingredient_list.customizable_count > 0 && (
                        <>
                          {ingredient_list.title && (
                            <h4 style={{ fontWeight: 500, marginBottom: '0' }}>
                              {minmax(ingredient_list.title, ingredient_list.min, ingredient_list.max)}
                            </h4>
                          )}
                          <br />
                          {ingredient_list.description && (
                            <sub className="description">{ingredient_list.description}</sub>
                          )}
                          {ingredient_list.menu_item_ingredients.map((menu_item_ingredient, index_5) => (
                            !!menu_item_ingredient.customizable && (
                              <Chip
                                style={{ backgroundColor: '#ff5330' }}
                                label={menu_item_ingredient.ingredient.name}
                                key={index_5}
                                onClick={(e) => addMenuItemIngredient(menu_item, menu_item_ingredient, ingredient_list, e)}
                              >
                                {menu_item_ingredient.price > 0 && (
                                  <span className="ingredientprice">{priceFilter(menu_item_ingredient.price)}</span>
                                )}
                              </Chip>
                            )
                          ))}
                        </>
                      )}
                    </Grid>
                  ))}
                </Grid>
              )}
              {error_message && (
                <Grid container>
                  <Grid xs={12} item>
                    <p style={{ fontSize: '13px', color: 'red' }}>
                      {error_message}
                    </p>
                  </Grid>
                </Grid>
              )}

              {Object.keys(selected_menu_item_ingredients || {}).length > 0 && (
                <Grid container>
                  <Grid xs={12} item>
                    <h4 style={{ fontWeight: 500, marginBottom: '0' }}>Selected Options</h4>
                    <Grid container>
                      <Grid item xs={5}>
                        {Object.keys(selected_ingredients).map((selected_ingredient, index_3) => {
                          if (selected_ingredients[selected_ingredient].count > 0) {
                            return (
                              <Chip
                                deleteIcon={<CloseIcon />}
                                key={index_3}
                                onDelete={(e) =>
                                  removeMenuItemIngredient(
                                    menu_item,
                                    selected_ingredients[selected_ingredient]
                                  )
                                }
                                label={
                                  selected_ingredients[selected_ingredient].count +
                                  'x' +
                                  selected_ingredients[selected_ingredient].ingredient.name
                                }
                              />
                            );
                          }
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {user &&(
                <Grid container>
                  <Grid item xs={12}>
                    <FavoriteBorderIcon style={{color:'#ef757f'}} />
                    { " " + getUserFriendsLikesForMenuItem(user, menu_item) + I18nRails.t("client.order_action.friends_like") }
                  </Grid>
                </Grid>
              )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12}>
            {(menuItemHasRequiredOptions(menu_item) || menuItemHasDescription(menu_item)) && (
              <Button
                className="btn-first"
                style={{height:'auto', width:'100%', padding:'5px'}}
                onClick={(e) => addToBasket(menu_item_details[menu_item.id], menu, e)}
              >
                {I18nRails.t("client.order_action.submit_order")}
              </Button>
            )}
            {existsInBasket(menu_item) && (
              <Button
                className="modelcart-btn btn-first"
                onClick={(e) => removeFromBasket(menu_item_details[menu_item.id], e)}
              >
                {I18nRails.t("client.order_action.remove")}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
